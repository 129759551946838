import { createSlice } from "@reduxjs/toolkit";
import { state } from "views/Dashboard/Analytics Dashboard/selectors";

export const initialState = {
  loading: false,
  taskData: [],
  avatarURL: null,
  reportLoading:false,
  reportData: false
};

const taskDataSlice = createSlice({
  name: "taskData",
  initialState,
  reducers: {
   fetchTaskData(state, action) {
    state.loading = true;
   },
   fetchTaskDataSuccess(state, action) {
     state.taskData = action.payload 
    state.loading = false;
   },
   fetchTaskDataFailure(state, action) {
     state.loading = false
   },
   fetchReport(state, action) {
    state.reportLoading = true;
   },
   fetchReportSuccess(state, action){
   state.reportLoading =  false;
   },
   fetchReportFailure(state, action) {
   state.reportLoading = false;
   },
   makeLoaderFalse(state, action) {
    state.loading = false;
   }
  },
});

export const { actions, reducer, name: sliceKey } = taskDataSlice;
