import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const selectDomain = (state) => state?.taskData || initialState;

export const selectTaskData = createSelector([selectDomain], (state) => state.taskData);

export const loading = createSelector([selectDomain], (state) => state.loading);

export const selectAvatarURL = createSelector(
    [selectDomain],
    (state) => state.avatarURL
  );

export const reportLoading = createSelector([selectDomain], (state)=> state.reportLoading);

export const reportData = createSelector([selectDomain], (state)=> state.reportData);