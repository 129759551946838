import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, sliceKey, reducer } from "../../../slice";
import * as selectors from "../../../selectors";
import { Box } from "@chakra-ui/react";
import { capitalizeStringUpdated } from "utils/commonFunctions";

const Address = ({ clientId, customerId }) => {
  const [clientAddress, setCustomerLink] = useState(null);
  const dispatch = useDispatch();
  const lientsAddresses = useSelector(selectors.selectClientAddresses);
  useEffect(() => {
    dispatch(actions.fetchOneClientAddresses({ clientId, customerId }));
  }, [clientId]);
  useEffect(() => {
    if (lientsAddresses && lientsAddresses[clientId]) {
      setCustomerLink(
        lientsAddresses[clientId][0]?.city
          ? capitalizeStringUpdated(lientsAddresses[clientId][0]?.city) +
              ", " +
              capitalizeStringUpdated(lientsAddresses[clientId][0]?.state)
          : "-"
      );
    }
  }, [lientsAddresses]);

  return <Box>{clientAddress}</Box>;
};

export default Address;
