import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { actions } from "./slice";
import * as API from "../../../services/apis";
import * as API1 from "services/index";

import { downloadPdf, downloadSummarySheet } from "utils/commonFunctions";
//Generator Functions
export function* handleFetchData({ payload }) {
  try {
    const res = yield call(API.getInspection, payload.payload);
    const { data } = res;
    yield put(actions.fetchDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchDataFailure(err?.response?.data));
  }
}

export function* handleFetchAddFilterDropdown({ payload }) {
  try {
    const res = yield call(API.getAddFilterDropdown, payload.payload);
    const { data } = res;
    yield put(actions.fetchAddFilterDropdownSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchAddFilterDropdownFailure(err?.response?.data));
  }
}

export function* handlefetchAllData({ payload }) {
  try {
    const res = yield call(API.getAllItems, payload.payload);
    const { data } = res;
    if (data.success) {
      payload.onSuccess(false);
    }
    yield put(actions.fetchAllDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchAllDataFailure(err?.response?.data));
  }
}

export function* handleFetchWarehousesData({ payload }) {
  try {
    const res = yield call(API.getWarehouses, payload);
    const { data } = res;
    yield put(actions.fetchWarehousesSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchWarehousesFailure(err?.response?.data));
  }
}

export function* handleViewReport({ payload }) {
  try {
    const { source, fileName } = payload;
    const res = yield call(API.fetchSASUrl, {
      source,
      blobName: fileName,
    });
    const { url } = res.data;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    document.body.removeChild(link);
    yield put(actions.viewReportSuccess());
  } catch (err) {
    yield put(actions.viewReportFailure());
  }
}

export function* handleDownloadReport({ payload }) {
  try {
    const { source, inspectionDetails } = payload;
    const res = yield call(API.fetchSASUrl, {
      source,
      blobName: inspectionDetails?.fileName,
    });
    downloadPdf(res.data.url, inspectionDetails);
    yield put(actions.downloadReportSuccess());
  } catch (err) {
    yield put(actions.downloadReportFailure());
  }
}
export function* handleDownloadSummarySheet({ payload }) {
  try {
    const { month, year ,clientId } = payload;
    
    console.log("Requesting summary sheet for:", month, year,clientId);
    const res = yield call(API.fetchSummarySheet, { month, year,clientId });
    downloadSummarySheet(res);
  } catch (error) {
    console.error("Error downloading summary sheet:", error);
    payload.onFailure()
  }
}
function* sendEmails({ payload }) {
  try {
    const response = yield call(API.sendReport, payload.data);
    yield put(actions.sendEmailSuccess());
    payload.onSuccess();
  } catch (error) {
    yield put(actions.sendEmailFailure(error?.response?.data));
    payload.onFailure(error?.message);
  }
}
export function* handleFetchInspectionsCountData({ payload }) {
  try {
    const res = yield call(API.getInspectionCountData, payload.payload);
    const { data } = res;
    yield put(actions.fetchInspectionsCountDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchInspectionsCountDataFailure(err?.response?.data));
  }
}
// to get the month number inspection was done
export function* handleFetchGeneratedReportMonths(action) {
  try {
    const res = yield call(API.getBarChartData, action.payload );
    const { data } = res;
    yield put(actions.fetchGeneratedReportMonthsSuccess(data.payload));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchGeneratedReportMonthsFailure(err?.response?.data));
  }
}
export function* handleClearState() {
  try {
    yield put(actions.clearStateSuccess());
  } catch (err) {
    yield put(actions.clearStateFailure(err));
  }
}

export function* handleFetchWorkflowData({ payload }) {
  try {
    const res = yield call(API1.fetchFormData, payload);
    const { data } = res;
    yield put(actions.fetchWorkflowDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchWorkflowDataFailure(err?.response?.data));
  }
}

export function* handleFetchBlockchainData({ payload }) {
  try {
    const res = yield call(API.fetchBlockchainData, payload);
    const { data } = res;
    yield put(actions.fetchBlockchainDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchBlockChainDataFailure(err?.response?.data));
  }
}

// export function* handleFetchClearStates({ payload }) {
//   // console.log(payload);
//   yield put(actions.fetchClearStatesSuccess());
// }

export function* handleFetchUserData({ payload }) {
  try {
    const res = yield call(API.getUserProfile, payload);
    const { data } = res;
    yield put(actions.fetchUserDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchUserDataFailure(err?.response?.data));
  }
}

export function* handleFetchJobRequest({ payload }) {
  try {
    const res = yield call(API.getInspectionJobDetails, payload);
    const { data } = res;
    yield put(actions.fetchJobDetailsSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchJobDetailsFailure());
  }
}

function* handleGetMultipleTasksOfJob(action) {
  try {
    const response = yield call(API.getTasks, action.payload);
    const { data } = response;
    console.log(data);
    yield put(actions.fetchTaskDetailsSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchTaskDetailsFailure(e));
  }
}

export function* handleFetchAvatarURL({ payload }) {
  try {
    const res = yield call(API.getPreDefinedURL, payload.payload);
    const { data } = res;
    yield put(actions.getAvatarURLSuccess(data?.payload));
  } catch (err) {
    yield put(actions.getAvatarURLFailure(err));
  }
}
function* handleFetchClientsByCustomerId(action) {
  try {
    const response = yield call(API.getClientsByCustomerId, action.payload);
    const { data } = response;
    yield put(actions.fetchClientsOfCustomerSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchClientsOfCustomerFailure(e));
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export function* dashboardSaga() {
  yield takeLatest(actions.fetchData.type, handleFetchData);
  yield takeLatest(actions.fetchWarehouses.type, handleFetchWarehousesData);
  yield takeLatest(actions.viewReport.type, handleViewReport);
  yield takeLatest(actions.sendEmail.type, sendEmails);
  yield takeLatest(actions.downloadReport.type, handleDownloadReport);
  yield takeLatest(actions.fetchAllData.type, handlefetchAllData);
  yield takeLatest(
    actions.fetchInspectionsCountData.type,
    handleFetchInspectionsCountData
  );
  yield takeLatest(
    actions.fetchAddFilterDropdown.type,
    handleFetchAddFilterDropdown
  );
  yield takeLatest(
    actions.downloadSummarySheet.type,
    handleDownloadSummarySheet
  );
  yield takeLatest(
    actions.fetchGeneratedReportMonths.type,
    handleFetchGeneratedReportMonths
  );
  yield takeLatest(actions.clearState.type, handleClearState);
  yield takeEvery(actions.fetchWorkflowData.type, handleFetchWorkflowData);
  yield takeEvery(actions.fetchBlockchainData.type, handleFetchBlockchainData);
  // yield takeEvery(actions.fetchClearStates, handleFetchClearStates);
  yield takeLatest(actions.fetchUserData.type, handleFetchUserData);
  yield takeLatest(actions.fetchAvatarURL.type, handleFetchAvatarURL);
  yield takeLatest(actions.fetchJobDetailsRequest.type, handleFetchJobRequest);
  yield takeLatest(
    actions.fetchTaskDetailsRequest.type,
    handleGetMultipleTasksOfJob
  );
  yield takeLatest(
    actions.fetchClientsOfCustomer.type,
    handleFetchClientsByCustomerId
  );
}
