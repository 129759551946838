import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const selectDomain = (state) => state?.userPage || initialState;

export const selectAllUsersForCustomer = createSelector(
  [selectDomain],
  (state) => {
    return state.allUsersForCustomer;
  }
);

export const selectAllUsersForClient = createSelector(
  [selectDomain],
  (state) => {
    return state.allUsersForClient;
  }
);

export const selectProfileImgUrlsArrForClientUser = createSelector(
  [selectDomain],
  (state) => state.profileImgUrlsArrForClientUser
);

export const selectClientUserRoles = createSelector([selectDomain], (state) => {
  return state.clientUserRoles;
});

export const selectClientsOfCustomers = createSelector(
  [selectDomain],
  (state) => state.clientsOfCustomer
);

export const selectProfileImgUrlsArrOfClient = createSelector(
  [selectDomain],
  (state) => state.profileImgUrlsArrOfClient
);

export const selectProfileImgUrlsArr = createSelector(
  [selectDomain],
  (state) => state.profileImgUrlsArr
);

export const selectAllCustomerUserRoles = createSelector(
  [selectDomain],
  (state) => state.allCustomerUserRoles
);

export const selectUserData = createSelector(
  [selectDomain],
  (state) => state.userData
);

export const selectAvatarUrl = createSelector(
  [selectDomain],
  (state) => state.avatarUrl || null
);

export const selectDepartmentDropDownForCustomer = createSelector(
  [selectDomain],
  (state) => state.departmentDropDownForCustomer
);

export const selectDesignationDropDownForCustomer = createSelector(
  [selectDomain],
  (state) => state.designationDropDownForCustomer
);

export const selectRoleDropDownForCustomer = createSelector(
  [selectDomain],
  (state) => state.roleDropDownForCustomer
);

export const selectStateDropDownForCustomer = createSelector(
  [selectDomain],
  (state) => state.stateDropDownForCustomer
);

export const selectCityDropDownForCustomer = createSelector(
  [selectDomain],
  (state) => state.cityDropDownForCustomer
);
export const selectClientUserData = createSelector(
  [selectDomain],
  (state) => state.clientUserData
);

export const selectLocation = createSelector(
  [selectDomain],
  (state) => state.locations
);