import React, { useEffect, useState } from "react";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "../slice";
import { CustomerSaga } from "../saga";

import { Box, Button, Flex, useToast } from "@chakra-ui/react";
import AddCustomerTop from "../components/addCustomer/AddCustomerTop";
import AddCustomerLocation from "../components/addCustomer/AddCustomerLocation";
import { fetchSasurl, uploadFile } from "services/index";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors";
import { add, set } from "lodash";
import { useHistory } from "react-router-dom";
import { generateIdSync } from "utils";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";

const AddCustomer = ({
  isEditing,
  oldCustomerDetails,
  oldLinks,
  oldCustomerAddresses,
  customerId,
  refreshLocations,
  oldLocations,
}) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: CustomerSaga });
  const [isChanged, setIsChanged] = useState(false);

  const avatarUrl = useSelector(selectors.selectAvatarUrl);
  const locations = useSelector(selectors.selectLocation);
  const dispatch = useDispatch();

  const [countryCode, setCountryCode] = useState("");

  const [customerDetails, setCustomerDetails] = useState({
    country: "",
    orgName: "",
    avatar: "",
    // businessType: "",
  });

  const [links, setLinks] = useState([
    { value: "", type: "email" },
    {
      value: "",
      type: "phone",
      countryCode: "",
    },
  ]);
  const [registeredAddress, setRegisteredAddress] = useState({
    id: generateIdSync(),
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
    city: "",
    state: "",
    isRegistered: true,
  });
  const [additionalAddress, setAdditionalAddress] = useState({
    id: generateIdSync(),
    type: "",
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
    city: "",
    state: "",
    isRegistered: false,
  });

  const [tempAddress, setTempAddress] = useState({
    type: "",
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
    city: "",
    state: "",
    isRegistered: false,
  });

  const [locationArray, setLocationArray] = useState([]);
  const [locationCompareArray, setLocationCompareArray] = useState([]);

  const setAddressData = (info, isRegistered) => {
    return {
      addressLine1: info.addressInfo?.addressLine1,
      addressLine2: info.addressInfo?.addressLine2 || "",
      postalCode: info.postalCode || "",
      city: info.city || "",
      state: info.state || "",
      isRegistered: isRegistered,
      type: info.addressInfo?.type,
    };
  };

  useEffect(() => {
    if (isEditing && oldLocations) {
      let tempArr = oldLocations.map((location) => {
        return {
          id: location.id,
          name: location.name || "",
          externalCode: location.externalCode,
          area: null,
          additionalProperties: {},
          city: location.city || "",
          contactPerson: location.contactPerson || "",
          originType: location.originType || "",
          addressLine1: location.address.addressLine1 || "",
          addressLine2: location.address.addressLine2 || "",
          postalCode: location.postalCode || "",
          state: location.state || "",
          links: location.links?.length
            ? location.links
            : [
                { value: null, type: "phone", countryCode: "" },
                { value: null, type: "email", countryCode: null },
              ],
          isEditing: false,
          isFromDB: true,
          isChanged: false,
          status: location.status || "",
          country: location.country || "",
        };
      });

      setLocationArray(tempArr);
      setLocationCompareArray(tempArr);
    }
  }, [oldLocations]);

  useEffect(() => {
    if (isEditing) {
      if (oldCustomerDetails) {
        let avatar = oldCustomerDetails?.avatar;
        avatar && dispatch(actions.fetchAvatarURL({ payload: avatar }));
        let data = {
          // businessType: oldCustomerDetails?.businessType,
          orgName: oldCustomerDetails?.name,
          avatar: oldCustomerDetails?.avatar,
        };
        setCustomerDetails(data);
      }
      if (
        oldCustomerDetails?.registrationInfo &&
        Object.keys(oldCustomerDetails?.registrationInfo).length !== 0
      ) {
        let data = {
          PAN: oldCustomerDetails.registrationInfo.PAN,
          CIN: oldCustomerDetails.registrationInfo.CIN,
          TIN: oldCustomerDetails.registrationInfo.TIN,
          TAN: oldCustomerDetails.registrationInfo.TAN,
          CLN: oldCustomerDetails.registrationInfo.CLN,
        };
        setRegistrationInfo((prevData) => {
          return { ...prevData, ...data };
        });
      }
    }
    if (oldCustomerAddresses && oldCustomerAddresses.length > 0) {
      let registerInfo = oldCustomerAddresses.find((info) => info.isRegistered);
      let additionalInfo = oldCustomerAddresses.find(
        (info) => !info.isRegistered
      );

      if (registerInfo) {
        if (registerInfo.country) {
          setCustomerDetails((prevDetails) => {
            return { ...prevDetails, country: registerInfo.country };
          });
        }

        const data = setAddressData(registerInfo, true);
        setRegisteredAddress({ ...data, id: generateIdSync() });

        if (additionalInfo) {
          const data = setAddressData(additionalInfo, false);
          setAdditionalAddress({ ...data, id: generateIdSync() });
          setTempAddress(data);
        }
      }
    }
    if (oldLinks && Object.keys(oldLinks).length > 0) {
      let customerId = oldCustomerDetails?.id;
      if (customerId) {
        let email = oldLinks[customerId]?.find(
          (link) => link?.linkType === "email"
        );
        let phone = oldLinks[customerId]?.find(
          (link) => link?.linkType === "phone"
        );
        setLinks((prevLinks) => {
          const updatedLinks = prevLinks.map((link) => {
            if (link.type === "email") return { ...link, value: email?.value };
            return {
              ...link,
              value: phone?.value,
              countryCode: phone?.countryCode,
            };
          });
          if (phone && phone?.countryCode)
            setCountryCode(
              phone.countryCode === "91" ? "India +91" : "UAE +971"
            );
          return updatedLinks;
        });
      }
    }
  }, [oldCustomerDetails, oldCustomerAddresses, oldLinks]);

  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [panIsValid, setPanIsValid] = useState(true);
  const [cinIsValid, setCinIsValid] = useState(true);
  const [tinIsValid, setTinIsValid] = useState(true);
  const [trnIsValid, setTrnIsValid] = useState(true);
  const [clnIsValid, setClnIsValid] = useState(true);
  const [pinCodeIsValid, setPinCodeIsValid] = useState(true);
  const [registrationInfo, setRegistrationInfo] = useState({
    PAN: "",
    TIN: "",
    CIN: "",
    TAN: "",
    CLN: "",
  });

  const handleUpdateCustomerData = () => {
    let customerData = { ...customerDetails, registrationInfo, links };
    if (oldLinks) {
      const newLinks = links.map((link) => {
        let type = link.type;
        let oldLink = oldLinks[customerId]?.find(
          (temp) => temp.linkType === type
        );
        let updatedLink = { ...link, id: oldLink?.customerLinkId };
        return updatedLink;
      });
      customerData = { ...customerData, links: newLinks };
    }
    dispatch(actions.updateCustomerData({ customerData, customerId }));

    let registerAddressId = null;
    let additionalAddressId = null;
    oldCustomerAddresses.forEach((info) => {
      if (info.isRegistered) {
        registerAddressId = info.customerAddressId;
      } else {
        additionalAddressId = info.customerAddressId;
      }
    });
    //to remove location id
    delete registeredAddress.id;
    delete additionalAddress.id;
    let allAddresses = [
      { ...registeredAddress, country: customerDetails.country },
      ...(additionalAddress.addressLine1
        ? [{ ...additionalAddress, country: customerDetails.country }]
        : []),
    ];
    allAddresses.forEach((addressData) => {
      if (addressData.isRegistered) {
        dispatch(
          actions.updateCustomerAddress({
            customerId,
            id: registerAddressId,
            addressData,
            onSuccess: (status) => {
              if (allAddresses.length === 1) {
                if (status) {
                  toast({
                    title: `Agency updated successfully`,
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                  });
                  history.push("/admin/agency");
                } else {
                  toast({
                    title: `Something went wrong`,
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                  });
                }
              }
            },
          })
        );
      } else {
        if (additionalAddressId) {
          dispatch(
            actions.updateCustomerAddress({
              customerId,
              id: additionalAddressId,
              addressData,
              onSuccess: (status) => {
                if (status) {
                  toast({
                    title: `Agency updated successfully`,
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                  });
                  history.push("/admin/agency");
                } else {
                  toast({
                    title: `Something went wrong`,
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                  });
                }
              },
            })
          );
        } else {
          const data = {
            addresses: [
              { ...additionalAddress, country: customerDetails.country },
            ],
          };
          dispatch(
            actions.addCustomersAddress({
              customerId,
              data,
              onSuccess: (status) => {
                if (status) {
                  toast({
                    title: `Agency updated successfully`,
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                  });
                  history.goBack("/admin/agency");
                } else {
                  toast({
                    title: `Something went wrong`,
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                  });
                }
              },
            })
          );
        }
      }
    });

    setTimeout(() => {
      dispatch(actions.clearStates());
    }, 1000);
    setProfileImagePreview(null);
    setIsChanged(false);

    // history.push("/admin/customers");
  };

  const handleRegisteredAddress = (field, value) => {
    if (isChanged === false) setIsChanged(true);
    if (field === "state") {
      setRegisteredAddress({
        ...registeredAddress,
        postalCode: "",
        city: "",
        [field]: value,
      });
      dispatch(
        actions.fetchLocationData({
          id: registeredAddress?.id,
          country:
            customerDetails?.country === "UAE"
              ? "United Arab Emirates"
              : customerDetails?.country,
          state: value,
        })
      );
    } else if (field === "city") {
      setRegisteredAddress({
        ...registeredAddress,
        postalCode: "",
        [field]: value,
      });
      dispatch(
        actions.fetchLocationData({
          id: registeredAddress?.id,
          country:
            customerDetails?.country === "UAE"
              ? "United Arab Emirates"
              : customerDetails?.country,
          state: registeredAddress?.state,
          city: value,
        })
      );
    } else {
      setRegisteredAddress({ ...registeredAddress, [field]: value });
    }
  };

  const handleAdditionalAddress = (field, value) => {
    if (isChanged === false) setIsChanged(true);
    setAdditionalAddress({ ...additionalAddress, [field]: value });
  };
  const handleLinksChange = (index, value) => {
    if (isChanged === false) setIsChanged(true);
    const newLinks = [...links];
    newLinks[index].value = value;
    setLinks(newLinks);
  };

  const handleRegistrationInfo = (field, value) => {
    if (isChanged === false) setIsChanged(true);
    setRegistrationInfo((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const [profileImagePreview, setProfileImagePreview] = useState(null);

  const handleInputChange = (field, value) => {
    if (isChanged === false) setIsChanged(true);
    setCustomerDetails((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleFileChange = async (event) => {
    if (!isChanged) setIsChanged(true);
    try {
      const file = event.target.files[0];

      if (file) {
        setProfileImagePreview(URL.createObjectURL(file));

        const fileName = file.name;
        let sasURLData = await fetchSasurl({
          fileName,
        });

        const sasURL = sasURLData && sasURLData.data.payload.url;

        // Handle potential error during upload
        const uploadResponse = await uploadFile({ file, sasURL });

        handleInputChange("avatar", fileName);
      }
    } catch (error) {
      console.error("Error handling file change:", error);
    }
  };

  const history = useHistory();
  const toast = useToast();

  // function for post customerDetails data
  const handlePostCustomerData = () => {
    const payload = {
      ...customerDetails,
      registrationInfo,
      links,
    };
    dispatch(actions.postCustomerData(payload));

    dispatch(actions.clearStates());
    setIsChanged(false);
  };

  const selectCustomerIds = useSelector(selectors.selectCustomerIds);
  const selectCustomerId = selectCustomerIds?.customerId;
  useEffect(() => {
    const data = {
      addresses: [
        { ...registeredAddress, country: customerDetails.country },
        ...(additionalAddress.addressLine1
          ? [{ ...additionalAddress, country: customerDetails.country }]
          : []),
      ],
    };
    if (
      selectCustomerId &&
      data.addresses.length > 0 &&
      data.addresses[0].country !== ""
    ) {
      dispatch(
        actions.addCustomersAddress({
          customerId: selectCustomerId,
          data,
          onSuccess: (status) => {
            if (status) {
              toast({
                title: `Agency Added successfully`,
                status: "success",
                duration: 2000,
                isClosable: true,
              });
              history.goBack();
            } else {
              toast({
                title: `Something went wrong.`,
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            }
          },
        })
      );
    }
  }, [selectCustomerId]);

  useEffect(() => {
    if (avatarUrl != null && isEditing === true) {
      setProfileImagePreview(avatarUrl.url);
    }
  }, [avatarUrl]);

  const additionalChecks = () => {
    const { country } = customerDetails;
    const { TIN, CIN, PAN, TAN, CLN } = registrationInfo;
    if (
      (country === "India" &&
        [TIN, CIN, PAN].some((value) => value === "" || value === undefined)) ||
      (country === "UAE" &&
        [TAN, CLN].some((value) => value === "" || value === undefined))
    ) {
      return true;
    }

    return false;
  };
  const invalidFields = () => {
    const { country } = customerDetails;

    if (
      (country.toLowerCase() === "india" &&
        (!tinIsValid ||
          !cinIsValid ||
          !panIsValid ||
          !pinCodeIsValid ||
          !phoneIsValid ||
          !emailIsValid)) ||
      (country.toLowerCase() === "uae" &&
        (!trnIsValid || !clnIsValid || !phoneIsValid || !emailIsValid))
    ) {
      return true;
    }
    return false;
  };
  const isAnyFieldEmpty =
    Object.entries(customerDetails).some(([key, value]) => {
      return key !== "avatar" && value === "";
    }) ||
    links.some((link) => link.value === "") ||
    Object.entries(registeredAddress).some(([key, value]) => {
      if (customerDetails.country === "UAE" && key === "postalCode") {
        return false;
      }
      return key !== "addressLine2" && value === "";
    }) ||
    (Object.entries(additionalAddress).some(
      ([key, value]) => key !== "isRegistered" && key !== "id" && value !== ""
    ) &&
      Object.entries(additionalAddress).some(([key, value]) => {
        if (customerDetails.country === "UAE" && key === "postalCode") {
          return false;
        }
        return key !== "addressLine2" && value === "";
      })) ||
    additionalChecks() ||
    invalidFields();

  const updateButtonState = () => {
    let res = true;

    if (!isAnyFieldEmpty && isChanged) res = false;
    return res;
  };

  useEffect(() => {
    if (customerDetails && customerDetails?.country) {
      dispatch(
        actions.fetchLocationData({
          id: registeredAddress?.id,
          country:
            customerDetails?.country === "UAE"
              ? "United Arab Emirates"
              : customerDetails?.country,
        })
      );
      // additionalAddress
      dispatch(
        actions.fetchLocationData({
          id: additionalAddress?.id,
          country:
            customerDetails?.country === "UAE"
              ? "United Arab Emirates"
              : customerDetails?.country,
        })
      );
    }
  }, [customerDetails?.country]);

  useEffect(() => {
    if (registeredAddress.postalCode?.length === 6) {
      dispatch(
        actions.fetchLocationData({
          id: registeredAddress?.id,
          postalCode: registeredAddress.postalCode,
          onSuccess: () => {
            if (!registeredAddress.state && !registeredAddress.city)
              dispatch(
                actions.fetchLocationData({
                  id: registeredAddress?.id,
                  country:
                    customerDetails?.country === "UAE"
                      ? "United Arab Emirates"
                      : customerDetails?.country,
                })
              );
          },
        })
      );
    }
  }, [registeredAddress.postalCode]);

  useEffect(() => {
    if (tempAddress.postalCode?.length === 6) {
      dispatch(
        actions.fetchLocationData({
          id: additionalAddress?.id,
          postalCode: tempAddress.postalCode,
          onSuccess: () => {
            if (!tempAddress.state && !tempAddress.city)
              dispatch(
                actions.fetchLocationData({
                  id: additionalAddress?.id,
                  country:
                    customerDetails?.country === "UAE"
                      ? "United Arab Emirates"
                      : customerDetails?.country,
                })
              );
          },
        })
      );
    }
  }, [tempAddress.postalCode]);

  useEffect(() => {
    if (
      (locations[registeredAddress?.id]?.states?.length === 1 &&
        locations[registeredAddress?.id]?.cities?.length) === 1
    ) {
      setRegisteredAddress((prev) => {
        return {
          ...prev,
          city: locations[registeredAddress?.id]?.cities[0],
          state: locations[registeredAddress?.id]?.states[0],
        };
      });
    }

    // for additional Address
    if (
      (locations[additionalAddress?.id]?.states?.length === 1 &&
        locations[additionalAddress?.id]?.cities?.length) === 1
    ) {
      setTempAddress((prev) => {
        return {
          ...prev,
          city: locations[additionalAddress?.id]?.cities[0],
          state: locations[additionalAddress?.id]?.states[0],
        };
      });
    }
  }, [locations]);

  useEffect(() => {
    return () => {
      setLocationArray([]);
      setLocationCompareArray([]);
    };
  }, []);

  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <Box mt={"75px"}>
        <Flex direction={"column"} gap={"36px"}>
          <AddCustomerTop
            customerDetails={customerDetails}
            handleInputChange={handleInputChange}
            handleFileChange={handleFileChange}
            profileImagePreview={profileImagePreview}
            handleRegistrationInfo={handleRegistrationInfo}
            registrationInfo={registrationInfo}
            handleLinksChange={handleLinksChange}
            links={links}
            handleRegisteredAddress={handleRegisteredAddress}
            registeredAddress={registeredAddress}
            handleAdditionalAddress={setAdditionalAddress}
            additionalAddress={additionalAddress}
            avatarUrl={avatarUrl}
            setLinks={setLinks}
            phoneIsValid={phoneIsValid}
            setPhoneIsValid={setPhoneIsValid}
            emailIsValid={emailIsValid}
            setEmailIsValid={setEmailIsValid}
            panIsValid={panIsValid}
            setPanIsValid={setPanIsValid}
            cinIsValid={cinIsValid}
            setCinIsValid={setCinIsValid}
            tinIsValid={tinIsValid}
            setTinIsValid={setTinIsValid}
            trnIsValid={trnIsValid}
            setTrnIsValid={setTrnIsValid}
            clnIsValid={clnIsValid}
            setClnIsValid={setClnIsValid}
            tempAddress={tempAddress}
            setTempAddress={setTempAddress}
            isEditing={isEditing}
            oldCustomerAddresses={oldCustomerAddresses}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            pinCodeIsValid={pinCodeIsValid}
            setPinCodeIsValid={setPinCodeIsValid}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            setRegisteredAddress={setRegisteredAddress}
            locations={locations}
            oldCustomerDetails={oldCustomerDetails}
          />
          {isEditing ? (
            <AddCustomerLocation
              locationArray={locationArray}
              setLocationArray={setLocationArray}
              locationCompareArray={locationCompareArray}
              setLocationCompareArray={setLocationCompareArray}
              refreshLocations={refreshLocations}
              customerId={customerId}
              isEditing={isEditing}
              customerDetails={customerDetails}
              locations={locations}
            />
          ) : (
            <></>
          )}
          <Flex justifyContent={"flex-end"}>
            <Button
              isDisabled={isEditing ? updateButtonState() : isAnyFieldEmpty}
              colorScheme="login"
              size="md"
              borderRadius={"8px"}
              onClick={
                isEditing ? handleUpdateCustomerData : handlePostCustomerData
              }
            >
              {isEditing && customerDetails && links
                ? "Save Changes"
                : "Add Agency"}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </ErrorBoundary>
  );
};

export default AddCustomer;
