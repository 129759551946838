import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, sliceKey, reducer } from "../../../slice";
import * as selectors from "../../../selectors";

const Email = ({ customerId, clientId }) => {
  const [clientLink, setclientLink] = useState(null);
  const dispatch = useDispatch();
  const clientsLinks = useSelector(selectors.selectClientLink);
  // console.log(clientsLinks);
  useEffect(() => {
    dispatch(actions.fetchOneClientLinks({ clientId, customerId }));
  }, [clientId]);

  useEffect(() => {
    if (
      clientsLinks &&
      clientsLinks[clientId] &&
      clientsLinks[clientId].length
    ) {
      clientsLinks[clientId]?.forEach((link) => {
        if (link.linkType === "email") {
          // console.log(link);
          setclientLink(link.value);
        }
      });
    }
  }, [clientsLinks]);

  return <div>{clientLink || "-"}</div>;
};

export default Email;
