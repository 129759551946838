import React, { useCallback, useEffect, useState } from "react";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  Box,
  HStack,
  PopoverBody,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  ButtonGroup,
  useDisclosure,
} from "@chakra-ui/react";
import EditableText from "views/Workflow Builder/Components/Editable";
import Form from "views/Workflow Builder/Components/Form";
import { Button } from "@agnext/reactlib";
import { ChevronDownIcon, ChevronUpIcon, AddIcon } from "@chakra-ui/icons";
import { actions, sliceKey, reducer } from "../Create/slice";
import { WorkflowManagerSaga } from "./saga";
import * as selectors from "./selectors";
import { useSelector, useDispatch } from "react-redux";
import { generateIdSync, generateId } from "../../../../utils";
import { userStorage } from "utils/helper";
import { useHistory, useParams } from "react-router-dom";
import FormImport from "views/Workflow Builder/Components/FormImport";
import Status from "views/Workflow Builder/Components/Status/Status";
import useToastNotification from "utils/useToastNotification";
import { CustomModal } from "../../Components/Modal/CustomModal";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";
import FallbackToast from "errorsFallback/FallbackToast";

function WorkflowManagerComp() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: WorkflowManagerSaga });

  const initialFocusRef = React.useRef();

  const { isOpen } = useDisclosure();
  const {
    isOpen: isOpenPublishModal,
    onOpen: onOpenPublishModal,
    onClose: onClosePublishModal,
  } = useDisclosure();

  const dispatch = useDispatch();
  const { workflowId } = useParams();
  const history = useHistory();
  const loggedInUser = userStorage.get()?.id;

  const [workflowTitle, setWorkflowTitle] = useState("Workflow Title");
  const [description, setDescription] = useState("Description");

  const workflow = useSelector(selectors.selectWorkflow);

  const loading = useSelector(selectors.loading);

  const forms = useSelector(selectors.selectForms);
  const showToast = useToastNotification();

  useEffect(() => {
    setWorkflowTitle(workflow && workflow.title);
    setDescription(workflow && workflow.description);
  }, [workflow]);

  const handleAddNewForm = () => {
    const newForm = {
      id: generateIdSync(),
      title: "Form",
      description: "Description",
      createdBy: loggedInUser,
      application: "perfeqt",
      categoryName: "inspection",
      categoryType: "",
      structure: {
        sections: [],
      },
    };
    //Addind new form to forms array.
    const formsCopy = _.cloneDeep(forms);
    formsCopy.sort((a, b) => a.order - b.order);
    const order = formsCopy[formsCopy.length - 1].order + 1;
    newForm.order = order;
    formsCopy.push(newForm);

    dispatch(actions.addNewForm(newForm));

    handlePostWorkflow("draft", formsCopy, "Form added successfully");
  };

  const handleWorkflowChange = (value) => {
    setWorkflowTitle(value);
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  // const handleClientChange = (value) => {
  //   setClient(value);
  // };

  // const handleApplicationChange = (value) => {
  //   setApplication(value);
  // };

  const handleWorkflowSubmit = (form) => {
    dispatch(actions.editWorkflowTitle(form));
  };

  const handleDescriptionSubmit = (description) => {
    dispatch(actions.editWorkflowDescription(description));
  };

  const getFormStatus = (formId) => {
    const form = forms?.filter((form) => {
      if (form?.id === formId) return form?.status;
    });
    return form[0]?.status || "draft";
  };

  const checkFormStatus = (forms) => {
    const formStatus = forms?.map((form) => {
      return getFormStatus(form?.id);
    });
    return formStatus?.includes("draft");
  };
  const handleDeleteForm = ({ formId, workflowId }) => {
    dispatch(
      actions.deleteForm({
        id: formId,
        workflowId,
        onSuccess: () => {
          fetchWorkflow();
          showToast({
            message: `Form deleted successfully`,
            status: "success",
          });
        },
        onError: () => {
          showToast({
            message: `Error in deleting Form`,
            status: "error",
          });
        },
      })
    );
  };

  const fetchWorkflow = () => {
    dispatch(actions.fetchWorkflow({ workflowId }));
  };

  useEffect(() => {
    if (workflowId) {
      dispatch(actions.fetchWorkflow({ workflowId }));
    } else {
      const formId = generateIdSync();
      let form = {
        id: formId,
        title: "Form",
        order: 1,
        description: "Description",
        createdBy: loggedInUser,
        application: "perfeqt",
        categoryName: "inspection",
        categoryType: "",
        structure: {
          sections: [],
        },
      };
      const workflow = {
        id: generateIdSync(),
        title: "Workflow",
        description: "Description",
        createdBy: loggedInUser,
        status: "draft",
      };
      dispatch(
        actions.initWorkflow({
          workflowData: { workflow },
          formData: { forms: [form] },
          workflowFormData: {
            workflowForms: [
              { formId, workflowId: workflow.id, order: 1, status: "draft" },
            ],
          },
          onSuccess: () => {
            console.log("init worflow success");
          },
          onError: () => {
            console.log("init worflow Failure");
          },
        })
      );
      dispatch(actions.init({ form, workflow }));
    }

    return () => {
      dispatch(actions.clearState());
    };
  }, [workflowId]);

  const handlePostWorkflow = (status, newForms = forms, toastMessage) => {
    dispatch(
      actions.initWorkflow({
        workflowData: { workflow: { ...workflow, status } },
        formData: { forms: newForms },
        workflowFormData: {
          workflowForms: newForms?.map((form) => ({
            formId: form.id,
            workflowId: workflow.id,
            status: status === "published" ? status : form?.status || "draft",
            order: form.order,
          })),
        },
        onSuccess: () => {
          if (status === "published") {
            dispatch(
              actions.fetchWorkflow({
                workflowId,
                onSuccess: () => {
                  showToast({
                    message: toastMessage || `Successfully ${status} `,
                    status: "success",
                  });
                  history.push("/admin/workflows");
                },
              })
            );
          } else {
            showToast({
              message: toastMessage || `Successfully ${status} `,
              status: "success",
            });
          }
        },
        onError: () => {
          showToast({
            message: `Something went wrong`,
            status: "error",
          });
        },
      })
    );
  };

  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <Box mt={"67px"} style={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
            borderRadius: "15px",
            padding: "8px 12px",
          }}
        >
          <Status status={workflow?.status} />
        </Box>
        <Box
          style={{
            padding: "16px",
            background: "#FFF",
            boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)",
            borderRadius: "15px",
          }}
          // justify="space-between"
        >
          <Box w="94%">
            <EditableText
              text={workflowTitle}
              handleTextChange={handleWorkflowChange}
              handleSubmit={handleWorkflowSubmit}
              wfStatus={workflow?.status}
            />

            <EditableText
              text={description}
              handleTextChange={handleDescriptionChange}
              handleSubmit={handleDescriptionSubmit}
              wfStatus={workflow?.status}
            />
          </Box>
        </Box>
        <HStack mt="32px" justify="space-between" align="flex-start" gap="24px">
          <ErrorBoundary
            fallback={<FallbackToast message={`Failed to Load Forms`} />}
          >
            <VStack w="calc(100% - 320px)" gap="24px">
              {forms ? (
                forms?.map((form) => (
                  <Form
                    key={form.id}
                    handlePostWorkflow={() => {
                      handlePostWorkflow("draft");
                    }}
                    formData={form}
                    workflowData={workflow}
                    handleDeleteForm={handleDeleteForm}
                    Status={<Status status={getFormStatus(form?.id)} />}
                    workflowStatus={workflow?.status}
                    numOfForm={forms.length}
                  />
                ))
              ) : (
                <></>
              )}
            </VStack>
          </ErrorBoundary>
          {workflow?.status === "published" ? (
            <></>
          ) : (
            <HStack
              w={workflow?.status === "published" ? "160px" : "400px"}
              sx={{
                position: "fixed",
                padding: "24px",
                right: "24px",
                bottom: "24px",
                boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)",
                justifyContent: "space-between",
                borderRadius: "15px",
                background: "#fff",
              }}
              justify="space-between"
            >
              <Popover
                initialFocusRef={initialFocusRef}
                closeOnBlur={true}
                matchWidth={true}
                isLazy
              >
                <PopoverTrigger>
                  <Button
                    size="md"
                    // onClick={handleAddNewForm}
                    colorScheme="login"
                    borderRadius="8px"
                    rightIcon={
                      isOpen ? (
                        <ChevronDownIcon boxSize={5} />
                      ) : (
                        <ChevronUpIcon boxSize={5} />
                      )
                    }
                    label="Add Form"
                  />
                </PopoverTrigger>
                <PopoverContent
                  w="280px"
                  borderColor="login.500"
                  sx={{
                    position: "absolute",
                    top: "-110px",
                    borderRadius: "15px",
                    padding: "8px 12px",
                  }}
                >
                  <ButtonGroup size="sm">
                    <VStack align="flex-start" gap="18px">
                      <FormImport fetchWorkflow={fetchWorkflow} />
                      <Button
                        size="sm"
                        borderRadius="8px"
                        onClick={handleAddNewForm}
                        colorScheme="login"
                        leftIcon={<AddIcon />}
                        label="New Form"
                      />
                    </VStack>
                  </ButtonGroup>
                </PopoverContent>
              </Popover>
              <Button
                size="md"
                borderRadius="8px"
                onClick={() => {
                  handlePostWorkflow(
                    "draft",
                    undefined,
                    "Workflow saved successfully"
                  );
                }}
                colorScheme="login"
                label="Save"
              />
              <Button
                isDisabled={loading || checkFormStatus(forms)}
                size="md"
                borderRadius="8px"
                onClick={onOpenPublishModal}
                colorScheme="login"
                label="Publish"
              />
            </HStack>
          )}
        </HStack>
        <CustomModal
          isOpen={isOpenPublishModal}
          onClose={onClosePublishModal}
          headerText="Publish Form"
          onButtonClick={() => {
            handlePostWorkflow(
              "published",
              undefined,
              "Workflow published successfully"
            );
          }}
          buttonText="Yes, I'm sure!"
          maxWidth="436px"
          colorScheme="login"
          bodyText={
            <span color="#718096" mb="10px">
              Are you sure you want to publish
              <span style={{ fontWeight: "500" }}>{` "${workflowTitle}"`}</span>
              ?
            </span>
          }
        />
      </Box>
    </ErrorBoundary>
  );
}

export default function WorkflowManager() {
  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <WorkflowManagerComp />
    </ErrorBoundary>
  );
}
