import { Table } from "@agnext/reactlib";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import Eye from "assets/svg/eye-icon.svg";
import Edit from "assets/svg/edit-icon.svg";
import AvatarWithName from "views/jobs/components/viewJob/Table Components/AvatarWithName";
import { extractDayMonthYearFromTimestamp } from "utils/commonFunctions";
import * as api from "services/apis";
import Email from "./TableComponents/Email";
import Address from "./TableComponents/Address";
const Icons = ({ customerId }) => {
  return (
    <Flex gap={2}>
      <Link to={`/admin/agency/viewAgency?agencyId=${customerId}`}>
        <Box
          background={"#EDF2F7"}
          height={"40px"}
          width={"48px"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius={"12px"}
          style={{ cursor: "pointer" }}
        >
          <Image src={Eye} alt="" />
        </Box>
      </Link>
      <Link to={`/admin/agency/updateAgency?agencyId=${customerId}`}>
        <Box
          background={"#EDF2F7"}
          height={"40px"}
          width={"48px"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius={"12px"}
          style={{ cursor: "pointer" }}
        >
          <Image src={Edit} alt="" />
        </Box>
      </Link>
    </Flex>
  );
};

const ProfileWithName = () => {
  return (
    <Flex gap={"15px"} alignItems={"center"}>
      <Box
        background={"#EDF2F7"}
        height={"32px"}
        width={"32px"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius={"50%"}
        style={{ cursor: "pointer" }}
      >
        {/* <Image src={Eye} alt="" /> */}
      </Box>

      <Box>
        <Text>Tarun Kumar</Text>
      </Box>
    </Flex>
  );
};

const CustomerRoleTable = ({ allCustomersDetail, customerProfileImgUrls }) => {
  const Header = [
    {
      displayText: "Organization Name",
      keyText: "orgName",
      hasSort: false,
    },
    // {
    //   displayText: "Business Type",
    //   keyText: "business",
    //   hasSort: true,
    // },
    {
      displayText: "Registered Address",
      keyText: "address",
      hasSort: false,
    },
    {
      displayText: "Email",
      keyText: "email",
      hasSort: false,
    },
    {
      displayText: "Created Date",
      keyText: "createdDate",
      hasSort: true,
    },
    {
      displayText: "Actions",
      keyText: "actions",
    },
  ];
  // const businessType = ["Bank", "Retail", "Inspection Agency"];
  const modifiedData = allCustomersDetail?.map((obj, index) => {
    return {
      ...obj,
      //  businessType: businessType[index]
    };
  });
  const tableList = allCustomersDetail?.map((customer, index) => {
    // console.log("customer", customer);
    return {
      orgName: (
        <AvatarWithName
          avatarUrl={
            customerProfileImgUrls ? customerProfileImgUrls[index]?.url : ""
          }
          name={customer.name}
        />
      ),
      // business: customer.businessType,
      address: <Address customerId={customer.customerId} />,
      email:
        <Email customerId={customer.customerId} /> || "example@example.com",
      createdDate: extractDayMonthYearFromTimestamp(customer.creationDt),
      actions: <Icons customerId={customer.customerId} />,
    };
  });
  return (
    <Box background={"white"} borderRadius={"12px"}>
      <Table
        overflow="auto"
        overflowY="auto"
        // tableContainerHeight={{ base: "441px", sm: "378px" }}
        headerList={Header}
        dataList={tableList}
        stickyColumn={["Actions"]}
      />
    </Box>
  );
};

export default CustomerRoleTable;
