import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { Box, HStack, Portal, Stack, Text, Tooltip } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { Button } from "@agnext/reactlib";
import _ from "lodash";
import * as selectors from "./selectors";
import { UpdateFormDataSaga } from "./saga";
import { actions, sliceKey, reducer } from "../Create/slice";
import Section from "./Section";
import { generateId } from "../../../../utils";
import InspectionTypes from "views/Workflow Builder/Components/InspectionTypes";
import useToastNotification from "utils/useToastNotification";
import Editable from "../../Components/Editable";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";

const validationErrorStyle = {
  border: "1px solid rgba(229, 62, 62)",
  borderRadius: "8px",
};

const UpdateForm = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: UpdateFormDataSaga });

  const dispatch = useDispatch();
  const { formId, workflowId } = useParams();

  let formData = useSelector(selectors.selectForm) || {};
  //  const forms = useSelector(selectors.selectForms) || [];
  const sections = useSelector(selectors.selectSectionsForBlocks) || [];
  const blocks = useSelector(selectors.selectBlocksForComposites) || [];
  const composites = useSelector(selectors.selectCompositesForComponents) || [];
  const components = useSelector(selectors.selectComponentsForElements) || [];
  const [validationState, setValidationState] = useState({});
  const [isValid, setIsValid] = useState(true);
  const showToast = useToastNotification();

  const history = useHistory();

  const [form, setForm] = useState("");

  const [description, setDescription] = useState("Description");

  const [inspectionType, setInspectionType] = useState({
    value: "",
    label: "",
  });
  useEffect(() => {
    setForm(formData && formData.title);
    setDescription(formData && formData.description);
    setInspectionType(
      formData &&
        ((prevState) => {
          return {
            ...prevState,
            value: formData.categoryType,
          };
        })
    );
  }, [formData.title, formData.description, formData.categoryType]);

  const checkValidity = () => {
    // checking if formData is valid
    if (!validationState.categoryType || !validationState.structure) {
      return false;
    }

    // checking if sections are valid
    for (let section of validationState.sections) {
      if (!section.title || !section.structure) {
        return false;
      }
    }

    // checking if blocks are valid
    for (let block of validationState.blocks) {
      if (!block.title || !block.structure) {
        return false;
      }
    }

    // checking if composites are valid
    for (let composite of validationState.composites) {
      if (!composite.title || !composite.structure) {
        return false;
      }
    }

    // checking if components are valid
    for (let component of validationState.components) {
      if (!component.title || !component.structure) {
        return false;
      }
    }

    // checking if elements are valid
    for (let element of validationState.elements) {
      if (!element.label || !element.htmlType) {
        return false;
      }

      // checking for options for htmltypes that need options
      if (
        [
          "checkbox",
          "checkboxSearch",
          "dropdown",
          "dropdownWithSearch",
          "radio",
        ].includes(element.htmlType) &&
        !element.options
      ) {
        return false;
      }

      // checking for fileOptions in case of htmlType fileUpload

      if (
        element.htmlType === "fileUpload" &&
        (!element.fileOptions ||
          !element.fileOptions?.allowedFileTypes ||
          !element.fileOptions?.maxFileCount ||
          !element.fileOptions?.maxFileSize)
      ) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    let validityState = {};
    // check for values of form key
    if (formData) {
      validityState.categoryType = Boolean(formData.categoryType);
      validityState.structure = Boolean(
        formData.structure && formData.structure.sections.length
      );
    }

    const sectionsValidity = [];
    const blocksValidity = [];
    const compositesValidity = [];
    const componentsValidity = [];
    const elementsValidity = [];

    // check for values in sections key
    if (sections.length) {
      sections.forEach((section) => {
        const sectionObj = {
          id: section.id,
          title: Boolean(section.title),
          // pageNumber: Boolean(section.pageNumber),
          structure: Boolean(
            section.structure &&
              section.structure.blocks &&
              section.structure.blocks.length
          ),
        };
        sectionsValidity.push(sectionObj);
      });
    }

    // check for values in blocks key
    if (blocks.length) {
      blocks.forEach((block) => {
        const blockObj = {
          id: block.id,
          title: Boolean(block.title),
          structure: Boolean(
            block.structure &&
              block.structure.composites &&
              block.structure.composites.length
          ),
        };
        blocksValidity.push(blockObj);
      });
    }

    // check for values in composites key
    if (composites.length) {
      composites.forEach((composite) => {
        const compositeObj = {
          id: composite.id,
          title: Boolean(composite.title),
          structure: Boolean(
            composite.structure &&
              composite.structure.components &&
              composite.structure.components.length
          ),
        };
        compositesValidity.push(compositeObj);
      });
    }

    // check for values in components key
    const elements = [];
    if (components.length) {
      components.forEach((component) => {
        const componentObj = {
          id: component.id,
          title: Boolean(component.title),
          structure: Boolean(
            component.structure &&
              component.structure.elements &&
              component.structure.elements.length
          ),
        };
        if (componentObj.structure) {
          const currElements = component.structure.elements;
          elements.push(...currElements);
        }
        componentsValidity.push(componentObj);
      });
    }

    // check for values of elements inside components key
    if (elements.length) {
      elements.forEach((element) => {
        let elementObj = {
          id: element.id,
          label: Boolean(element.label),
          htmlType: element.htmlType,
        };
        if (element.htmlType === "fileUpload") {
          if (!element.fileOptions) {
            elementObj.fileOptions = {
              allowedFileTypes: false,
              maxFileCount: false,
              maxFileSize: false,
            };
          } else {
            elementObj.fileOptions = {
              allowedFileTypes: Boolean(element.fileOptions.allowedFileTypes),
              maxFileCount: Boolean(element.fileOptions.maxFileCount),
              maxFileSize: Boolean(element.fileOptions.maxFileSize),
            };
          }
        }
        if (
          [
            "radio",
            "checkbox",
            "checkboxSearch",
            "dropdown",
            "dropdownWithSearch",
          ].includes(element.htmlType)
        ) {
          if (
            !element.properties ||
            !element.properties.options ||
            !element.properties.options.length
          ) {
            elementObj.options = false;
          } else {
            elementObj.options = true;
          }
        }

        elementsValidity.push(elementObj);
      });
    }

    validityState.sections = sectionsValidity;
    validityState.blocks = blocksValidity;
    validityState.composites = compositesValidity;
    validityState.components = componentsValidity;
    validityState.elements = elementsValidity;

    setValidationState(validityState);
  }, [formData, sections, blocks, composites, components]);

  useEffect(() => {
    dispatch(actions.fetchFormData({ id: formId }));
    return () => {
      dispatch(actions.clearFormData());
      setForm("");
      setDescription("");
      setInspectionType({
        value: "",
        label: "",
      });
    };
  }, [dispatch, formId]);

  const handleUpdateData = (status) => {
    const onSuccess = () => {
      showToast({
        message: `Form ${status} successfully`,
        status: "success",
      });
      if (status === "completed") {
        history.push(`/admin/workflows/${workflowId}`);
      }
    };
    const onError = () => {
      showToast({
        message: `Something Went Wrong`,
        status: "error",
      });
    };
    //(Below if condition)In case while creating a form, user adds 3 pages and selects radio element type and selects an option in Navigate to dropdown. Then if user reduce the number of page to less than 3 pages in this case the navigation option  must be removed from properties key in component while form post.
    let componentsData = _.cloneDeep(components);
    if (sections.length < 3) {
      componentsData.forEach((component) => {
        if (component.structure && component.structure.elements) {
          component.structure.elements.forEach((element) => {
            if (element.properties && element.properties.navigations) {
              return delete element.properties.navigations;
            }
          });
        }
      });
    }

    dispatch(
      actions.postFormData({
        data: {
          forms: [{ ...formData, status }],
          sections,
          blocks,
          composites,
          components: componentsData,
        },
        onSuccess,
        onError,
      })
    );
  };

  const handleInspectionTypeChange = (option) => {
    setInspectionType(option);
    dispatch(
      actions.editInspectionType({
        inspectionType: option.value,
        formId,
      })
    );
  };

  const handleAddSection = async () => {
    const sectionId = await generateId();
    const blockId = await generateId();
    const compositeId = await generateId();
    const componentId = await generateId();

    dispatch(actions.initNewSection({ sectionId, formId }));
    dispatch(actions.initNewBlock({ blockId, sectionId }));
    dispatch(actions.initNewComposite({ compositeId, blockId }));
    dispatch(actions.initNewComponent({ componentId, compositeId }));
  };

  useEffect(() => {
    dispatch(
      actions.fetchFormData({
        id: formId,
        onSuccess: () => {
          dispatch(actions.fetchWorkflow({ workflowId }));
        },
      })
    );
  }, [dispatch]);

  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <Box
        p={"15px"}
        borderRadius={"8px"}
        mt={"75px"}
        bg={"#fff"}
        overflow="hidden"
      >
        <HStack style={{ padding: "0px 0 40px 0" }} justify="space-between">
          <Box w={"60%"}>
            <Text
              color="#2D3748"
              fontSize="18px"
              fontWeight="600"
              lineHeight="1.5"
            >
              {form}
            </Text>
            <HStack
              style={{
                padding: "16px 0 0 0",
                borderTop: "1px solid #efefef",
              }}
              justify="space-between"
            >
              <Box style={{ width: "33%" }}>
                <Text
                  color="#2D3748"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="1.5"
                >
                  {description}
                </Text>
              </Box>
              <HStack
                height="32px"
                spacing="4" // Adjust spacing as needed
                alignItems="center" // Vertically center the items
              >
                <Text
                  color="#2D3748"
                  fontSize="16px"
                  fontWeight="600"
                  lineHeight="1.5"
                  w="40%"
                >
                  {"Inspection Type"}
                </Text>
                <Stack w="60%">
                  <InspectionTypes
                    option={inspectionType}
                    onOptionChange={handleInspectionTypeChange}
                    errorStyle={
                      !isValid && !validationState.categoryType
                        ? validationErrorStyle
                        : {}
                    }
                  />
                </Stack>
              </HStack>
            </HStack>
          </Box>
          <Box
            w={"40%"}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              size="sm"
              borderRadius="8px"
              onClick={handleAddSection}
              colorScheme="login"
              leftIcon={<AddIcon />}
              label="Page"
            />
          </Box>
        </HStack>
        {formData &&
          formData.structure &&
          formData.structure.sections &&
          formData.structure.sections.map((section) => (
            <Section
              key={section.id}
              sectionId={section.id}
              sectionOrder={section.order}
              isValid={isValid}
              validationState={validationState}
              errorStyle={validationErrorStyle}
              sectionOrderLast={
                formData.structure.sections.length >= 1
                  ? formData.structure.sections[
                      formData.structure.sections.length - 1
                    ].order
                  : 0
              }
            />
          ))}
        {formData &&
        formData.structure &&
        formData.structure.sections &&
        formData.structure.sections.length ? (
          <>
            <Box
              style={{
                position: "fixed",
                right: "37px",
                bottom: "16px",
                boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)",
              }}
              w="220px"
              height="65px"
              p="17px"
              bg="white"
              borderRadius="15px"
              border="var(--border-radius-0, 1px) solid var(--gray-200, #E2E8F0)"
            >
              <Tooltip hasArrow label="Save now, finish later." placement="top">
                <HStack
                  style={{ position: "fixed", right: "125px", bottom: "32px" }}
                >
                  <Button
                    size="sm"
                    borderRadius="8px"
                    onClick={() => {
                      handleUpdateData("draft");
                    }}
                    colorScheme="login"
                    label="Save As Draft"
                  />
                </HStack>
              </Tooltip>
              <Tooltip
                hasArrow
                label="Please complete all the required fields to finish."
                placement="top-end"
              >
                <HStack
                  style={{ position: "fixed", right: "53px", bottom: "32px" }}
                >
                  <Button
                    size="sm"
                    borderRadius="8px"
                    onClick={() => {
                      const validity = checkValidity();
                      setIsValid(validity);
                      if (validity) {
                        handleUpdateData("completed");
                      }
                    }}
                    colorScheme="login"
                    label="Finish"
                  />
                </HStack>
              </Tooltip>
            </Box>
          </>
        ) : (
          <></>
        )}
      </Box>
    </ErrorBoundary>
  );
};

export default function Update() {
  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <UpdateForm />
    </ErrorBoundary>
  );
}
