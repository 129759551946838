import { Button, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const ClientsTop = ({ totalClients }) => {
  return (
    <HStack justifyContent={"space-between"} alignItems={"flex-start"}>
      <Text fontSize={"18px"} fontWeight={700}>
        {totalClients < 2
          ? totalClients + " Client"
          : totalClients + " Clients"}
      </Text>
      <Link to="/admin/clients/addClient">
        <Button
          colorScheme="login"
          bg={"#38B2AC"}
          size="md"
          borderRadius={"8px"}
        >
          + Add Client
        </Button>
      </Link>
    </HStack>
  );
};

export default ClientsTop;
