import React from "react";
import { TABLE_HEADERS } from "../components/variables/Variables";
import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import { Table } from "@agnext/reactlib";
import locationBlack from "assets/svg/locationBlack.svg";
import { capitalizeStringUpdated } from "utils/commonFunctions";

const ViewCustomerLocation = ({ locationArray }) => {
  const tableHeaders = TABLE_HEADERS.partialHeaders;
  const tableData =
    locationArray?.length &&
    locationArray
      .sort(({ name: a }, { name: b }) => (a > b ? 1 : b > a ? -1 : 0))
      .map((location) => {
        let phoneNumber = "-";
        const phoneLink = location?.links?.find((link) => link.type === "phone");
        if (phoneLink?.value) {
          phoneNumber = `+${phoneLink.countryCode} ${phoneLink.value}`;
        }
        return {
          name: capitalizeStringUpdated(location.name) || "-",
          contactPerson: capitalizeStringUpdated(location.contactPerson) || "-",
          email: Array.isArray(location.links)
            ? location.links.find((link) => link.type === "email")?.value ?? ""
            : "-",
          phoneNumber,
          locationType: capitalizeStringUpdated(location.originType) || "-",
          address:
            (location.addressLine1 ? capitalizeStringUpdated(location.addressLine1) : "") +
            (location.addressLine2 ? "," + capitalizeStringUpdated(location.addressLine2) : "") +
            (location.city ? ", " + location.city : "") +
            (location.state ? ", " + location.state : "") +
            (location.postalCode ? ", " + location.postalCode : ""),
        };
      });

  return (
    <Stack spacing={"24px"}>
      <Box>
        <Text fontWeight={600}>Locations</Text>
      </Box>
      {locationArray?.length ? (
        <Box background={"white"} borderRadius={"12px"}>
          <Table
            overflow="auto"
            overflowY="auto"
            stickyColumn={["Actions"]}
            headerList={tableHeaders}
            dataList={tableData}
            containerStyle={{ maxHeight: "300px" }}
          />
        </Box>
      ) : (
        <Flex
          alignItems="center"
          h="64px"
          background={"white"}
          borderRadius={"12px"}
          padding={"16px 24px"}
        >
          <Flex gap="8px" alignItems="center">
            <Image w="24px" h="24px" src={locationBlack}></Image>
            <Text fontSize={"14px"}>No location to show.</Text>
          </Flex>
        </Flex>
      )}
    </Stack>
  );
};

export default ViewCustomerLocation;
