import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as IIconSvg } from "../../../../assets/svg/iIcon.svg";
import { Loader } from "../../../../components/Loader/Loader";
import { ReactComponent as GaugeEncircle } from "../../../../assets/svg/GaugeEncircle.svg";
import { Container, Box, Text, Heading, Card, VStack,Flex, Tooltip, Tag } from "@chakra-ui/react";
import { userStorage } from "utils/helper";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { useDispatch, useSelector } from "react-redux";
import { actions, sliceKey, reducer } from "./slice";
import { taskAnalyticsSaga } from "./saga";
import { useUserContext } from "../../../../context/UserContext"
import { getCurrentProfile } from "../../../../utils/commonFunctions";
import * as selectors from "./selectors";
import NewGaugeChart from "./element/NewGaugeChart";
import AnalyticsDashboard from "../../Analytics Dashboard";
import CardSection from "./element/CardSection";
import Overdue from "../../../../assets/svg/Overdue.svg";
import Inprogress from "../../../../assets/svg/Inprogress.svg";
import Schedule from "../../../../assets/svg/Schedule.svg";
import Complete from "../../../../assets/svg/Complete.svg";
import RightArrow from "../../../../assets/svg/RightArrow.svg";

const headingStyle = {
    fontSize: "28px",
    fontWeight: "500",
    lineHeight: "36px",
    minWidth: "328px",
    height: "36px",
    color: "#2D3748",
};

const minHeadingStyle = {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    minWidth: "328px",
    height: "24px",
    color: "#4A5568",
};

const cardStyle = { borderRadius: "16px", background: "linear-gradient(to bottom right, #0A4E18, #166D5D)", boxSizing: "border-box", boxShadow: "0px 4px 6px -2px #0000000D, 0px 4px 6px -2px rgba(10, 78, 24, 0.1), 0px 10px 15px -3px #0000001A, 0px 10px 15px -3px rgba(22, 109, 93, 0.0)" };

const GaugeWrapper = ({ scorePercent }) => {
    return (
        <Box position="relative" w="100%" bottom="4">
            <NewGaugeChart score={scorePercent} width={245.5} height={245} />
            <Box position="absolute" top="-3" left="7" right="2" bottom="0">
                <GaugeEncircle style={{ width: "90%", height: "90%" }} />
            </Box>
        </Box>
    );
};

const CustomToolTip = () => (
    <Tooltip sx={{fontSize:'12px', backgroundColor:"rgba(0, 0, 0, 0.7)"}} label='Percentage of inspections completed vs total inspections assigned'>
      <IIconSvg/>
    </Tooltip>
  )

 

function GenerateDashboard() {
    const { userProfileData } = useUserContext();
    const entity = getCurrentProfile(userProfileData?.profiles);
    let isFor = entity?.role?.name;
  
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: taskAnalyticsSaga });
    const dispatch = useDispatch();
    const { totalTask, inprogressTasks, completedTasks, pendingTasks, pendingOverDueTasks, completedOverDueTasks } = useSelector(selectors.selectAnalyticsTaskData) || {};
    const load = useSelector(selectors.loading);

    const scorePercent = ((completedTasks / totalTask) * 100).toFixed(1);

    const firstName = userStorage.get().firstName;

    useEffect(() => {
        dispatch(
            actions.fetchTaskAnalyticsData({
                payload: null,
            })
        );
    }, []);

    return (
        <>
            {load === true ? (
                <Loader position="center" margin="90px" />
            ) : isFor === "auditor" ? (
                <Container mt="75px">
                    <Box sx={{ textAlign: "center", fontStyle: "Roboto" }}>
                        <Heading sx={headingStyle}>Hi, {firstName} 👋</Heading>
                        <Text sx={minHeadingStyle}>Welcome back!</Text>
                    </Box>
                    <Card mt="45px" minW="328px" h="257px" p="22px" sx={cardStyle}>
                        <VStack>
                            <Box minW="95%">
                                <Flex direction="row" justify="space-between" align="center">
                                    <Box fontFamily="Noto Sans" fontWeight="600" fontSize="16px" lineHeight="24px" color="white">
                                        Completion Score
                                    </Box>
                                    <Box>
                                        <CustomToolTip/>
                                    </Box>
                                </Flex>
                            </Box>
                            <Box>
                                <GaugeWrapper scorePercent={scorePercent} />
                            </Box>
                        </VStack>
                    </Card>
                    <Flex as="section" direction="column" gap="16px" mt="30px" minW="328px">
                        <Link to="/admin/inspection-menu?tab=0">
                            <CardSection count={pendingOverDueTasks} status="Overdue" img={Overdue} rt={RightArrow} />
                        </Link>
                        <Link to="/admin/inspection-menu?tab=1">
                            <CardSection count={inprogressTasks} status="In Progress" img={Inprogress} rt={RightArrow} />
                        </Link >
                        <Link  to="/admin/inspection-menu?tab=0">
                            <CardSection count={pendingTasks} status="Scheduled" img={Schedule} rt={RightArrow} />
                        </Link>
                        <Link  to="/admin/inspection-menu?tab=2">
                            <CardSection count={completedTasks} status="Completed" img={Complete} rt={RightArrow} />
                        </Link>
                    </Flex>
                </Container>
            ) : (
                <AnalyticsDashboard />
            )}
        </>
    );
}

export default GenerateDashboard;
