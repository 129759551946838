import { Box, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ClientRoleTable from "../components/allClients/ClientRoleTable";
import ClientsTop from "../components/allClients/ClientsTop";
import ClientRoleFilters from "../components/allClients/ClientRoleFilters";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { watchClientSaga } from "../saga";
import { actions, reducer, sliceKey } from "../slice";
import * as selectors from "../selectors";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { useUserContext } from "context/UserContext";
import { getCurrentProfile } from "utils/commonFunctions";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";
import FallbackToast from "errorsFallback/FallbackToast";

const Client = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: watchClientSaga });

  const { userProfileData } = useUserContext();
  const entity = getCurrentProfile(userProfileData?.profiles);
  const customerId = entity?.ownerEntityId;

  // const customerId = "501322a0-fc53-4346-9bce-da4fa5d481d3";
  // // const customerId = "401c1052-3290-4aae-88e4-f8bc941ebf3b";

  const clientsOfCustomer = useSelector(selectors.selectClientsOfCustomers);

  const customerProfileImgUrls = useSelector(selectors.selectProfileImgUrlsArr);
  const cityDropdown = useSelector(selectors.selectCityDropdown);
  const stateDropdown = useSelector(selectors.selectStateDropdown);
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    fromDate: null,
    toDate: null,
    order: "DESC",
    businessTypes: [],
    states: [],
    cities: [],
    isActive: "",
  });

  const handleSelection = (prevSelectedItems, itemId) => {
    if (prevSelectedItems.includes(itemId)) {
      return prevSelectedItems.filter((id) => id !== itemId);
    } else {
      return [...prevSelectedItems, itemId];
    }
  };

  const handleClientListFilters = (id, key) => {
    setFilters((prevFilters) => {
      switch (key) {
        case "businessTypes":
          return {
            ...prevFilters,
            businessTypes: handleSelection(prevFilters.businessTypes, id, key),
          };
        case "states":
          return {
            ...prevFilters,
            states: handleSelection(prevFilters.states, id, key),
          };
        case "cities":
          return {
            ...prevFilters,
            cities: handleSelection(prevFilters.cities, id, key),
          };
        case "isActive":
          return { ...prevFilters, isActive: id };
        default:
          return prevFilters;
      }
    });
  };

  const handleFromDateChange = (date) => {
    setFilters((prevState) => ({
      ...prevState,
      fromDate: date,
    }));
  };
  const handleToDateChange = (date) => {
    setFilters((prevState) => ({
      ...prevState,
      toDate: date,
    }));
  };

  useEffect(() => {
    dispatch(
      actions.fetchClientsOfCustomer({
        ...filters,
        customerId,
      })
    );
    dispatch(actions.clearStates());
  }, [customerId, filters]);
  useEffect(() => {
    if (clientsOfCustomer) {
      let profileImgNameArr = clientsOfCustomer.map((customer) => {
        return customer.avatar;
      });
      dispatch(actions.fetchProfileImgURLArr(profileImgNameArr));
    }
  }, [clientsOfCustomer]);
  

  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <Box mt={"75px"}>
        <Stack spacing={"36px"} direction="column">
          <ErrorBoundary
            fallback={
              <FallbackToast message={`Failed to Load Add client Option`} />
            }
          >
            <ClientsTop totalClients={clientsOfCustomer?.length || 0} />
          </ErrorBoundary>
          <ErrorBoundary
            fallback={<FallbackToast message={`Failed to Load filters `} />}
          >
            <ClientRoleFilters
              handleClientListFilters={handleClientListFilters}
              handleFromDateChange={handleFromDateChange}
              handleToDateChange={handleToDateChange}
              filters={filters}
              cityDropdown={cityDropdown}
              stateDropdown={stateDropdown}
            />
          </ErrorBoundary>
          <ErrorBoundary fallback={<FallbackUI minH="70vh" />}>
            <ClientRoleTable
              clientsOfCustomer={clientsOfCustomer}
              customerProfileImgUrls={customerProfileImgUrls}
              customerId={customerId}
            />
          </ErrorBoundary>
        </Stack>
      </Box>
    </ErrorBoundary>
  );
};

export default Client;
