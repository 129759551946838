import React, { useEffect, useState } from "react";
import {
  HStack,
  Box,
  Input,
  VStack,
  Text,
  useMediaQuery,
  useToast,
  Image,
  CircularProgress,
} from "@chakra-ui/react";
import { FiUploadCloud, FiFile } from "react-icons/fi";
import { IoMdCloseCircle } from "react-icons/io";
import { actions, sliceKey, reducer } from "../../pages/reviewWorkflow/slice";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import SparkMD5 from "spark-md5";
import { useDispatch, useSelector } from "react-redux";
import { workflowDataSaga } from "../../pages/reviewWorkflow/saga";
import * as selectors from "../../pages/reviewWorkflow/selectors";
import { Loader } from "components/Loader/Loader";
import { fetchSasurl } from "services/index";
import { IoCloseCircleOutline } from "react-icons/io5";
import closeBtn from "../../../../assets/svg/closeCircle.svg";
import { v4 as uuidv4 } from "uuid";
import { getAllUrls } from "services/apis";
import "./indexReview.css";
//This component is specific to review pages.

export default function FileUploadsReview({
  data,
  formData,
  handleFormDataChange,
}) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: workflowDataSaga });

  const dispatch = useDispatch();

  const selectFilesUploadLoader = useSelector(
    selectors.selectFilesUploadLoader
  );

  const selectUploadedFiles = useSelector(selectors.selectUploadedFiles);

  const [isMobileScreen] = useMediaQuery("(max-width: 872px)");
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [savedFileNames, setSavedFileName] = useState({});

  const [filesForDisplay, setFilesForDisplay] = useState(
    formData[data.element.id]?.attachments || []
  );

  const [allHashedFiles, setAllHashedFiles] = useState(
    formData[data.element.id]?.itemInfo?.hashArray !== undefined
      ? formData[data.element.id]?.itemInfo?.hashArray
      : []
  );
  const toast = useToast();

  const openImageInNewTab = (url) => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    const initialFiles = formData[data.element.id]?.attachments || [];
    if (
      acceptTypes === "image/*" &&
      Object.keys(selectUploadedFiles).length === 0
    ) {
      dispatch(
        actions.getAllUrls({
          fileNames: formData[data.element.id]?.attachments,
          id: data.element.id,
        })
      );
    }
    const fileNames =
      initialFiles.map((fileName) => {
        let deleteFileName =
          fileName.split("_perfeqt_")[0] + "." + fileName.split(".")[1];
        return deleteFileName;
      }) || [];
    setFilesForDisplay(initialFiles);
    setSavedFileName({ [data.element.id]: fileNames });
  }, [formData, data.element.id]);

  const handleInputClick = (id) => {
    document.getElementById(id).click();
  };

  const showWarningToast = () => {
    toast({
      title: "File Already Uploaded",
      status: "info",
      duration: 1300,
      isClosable: true,
      position: "bottom-left",
    });
  };

  async function hashFiles(files) {
    const hashedFiles = [];

    const promises = files.map(async (file) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      return new Promise((resolve, reject) => {
        fileReader.onloadend = async () => {
          try {
            const hash = SparkMD5.ArrayBuffer.hash(fileReader.result);
            const fileNameArr = file.name.split(".");
            const type = fileNameArr.pop();
            const fileName = `${fileNameArr.join("")}_perfeqt_${
              data.element.id
            }.${type}`;
            hashedFiles.push({ name: fileName, hash });
            resolve();
          } catch (error) {
            reject(error);
          }
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    });

    await Promise.all(promises);
    return hashedFiles;
  }

  const handleFileChange = async (e) => {
    const totalFilesCount = filesForDisplay.length + e.target.files.length;
    const fileCount = data?.element?.fileOptions?.maxFileCount;
    if (totalFilesCount > fileCount) {
      toast({
        title: `You cannot add more than ${fileCount} files.`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-left",
      });
      return;
    }

    const maxFileSizeMB = data?.element?.fileOptions?.maxFileSize;
    const filesExceedingSize = Array.from(e.target.files).filter((file) => {
      return file.size > maxFileSizeMB * 1024 * 1024;
    });

    if (filesExceedingSize.length > 0) {
      toast({
        title: `Files larger than ${maxFileSizeMB} mb cannot be uploaded.`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-left",
      });
      return;
    }

    let files = [...filesToUpload];
    files.push(...Array.from(e.target.files));

    let uniqueFiles = files.filter((file) => {
      if (
        savedFileNames[data.element.id] &&
        savedFileNames[data.element.id].includes(file.name)
      ) {
        showWarningToast();
        return false;
      } else {
        setSavedFileName((prevState) => {
          return {
            ...prevState,
            [data.element.id]: [
              ...(prevState[data.element.id] || []),
              file.name,
            ],
          };
        });
        return true;
      }
    });

    let hashedFiles = await hashFiles(uniqueFiles);
    setAllHashedFiles((prev) => {
      return [...prev, ...hashedFiles];
    });

    let toSaveHashedFiles = [...allHashedFiles, ...hashedFiles];

    setFilesToUpload(uniqueFiles);

    files = Object.values(uniqueFiles);

    const fileNames = [...filesForDisplay];

    let mimeTypes =
      formData[data.element.id]?.mimeType &&
      formData[data.element.id]?.mimeType !== "NA"
        ? formData[data.element.id]?.mimeType
        : "";

    const token = localStorage.getItem("token");

    files.forEach((file) => {
      const fileNameArr = file.name.split(".");
      const type = fileNameArr.pop();
      const fileName = `${fileNameArr.join("")}_perfeqt_${
        data.element.id
      }.${type}`;

      mimeTypes = mimeTypes ? mimeTypes + "," + file.type : file.type;

      if (token) {
        dispatch(
          actions.postFileData({
            fileName,
            file,
            id: data.element.id,
            token: JSON.parse(token),
            onFailure: () => {
              afterFailure();
            },
            onSuccess: (sasURL) => {
              afterSuccess(fileName, sasURL);
            },
          })
        );
      } else {
        dispatch(
          actions.postFileData({
            fileName,
            file,
            id: data.element.id,
            onFailure: () => {
              afterFailure();
            },
            onSuccess: () => {
              afterSuccess(fileName);
            },
          })
        );
      }
    });

    const afterSuccess = (fileName, sasURL) => {
      fileNames.push(fileName);
      setFilesForDisplay(fileNames);
      setFilesToUpload([]);
      handleFormDataChange(
        toSaveHashedFiles,
        fileNames,
        data.element.id,
        mimeTypes
      );
    };

    const afterFailure = () => {
      setFilesToUpload([]);
      toast({
        title: `File upload failed. Please try again.`,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "bottom-left",
      });
    };
  };
  const handleFileDelete = (index, fileName) => {
    document.getElementById(data.element.id).value = "";
    let deleteFileName =
      fileName.split("_perfeqt_")[0] + "." + fileName.split(".")[1];

    const fileNames = filesForDisplay.filter((file, i) => i !== index);

    setAllHashedFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((prevFile) => {
        const key = prevFile.name;
        return key !== fileName;
      });
      return updatedFiles;
    });

    const toSaveHashedFiles = allHashedFiles.filter((file) => {
      const key = file.name;
      return key !== fileName;
    });

    setSavedFileName((prevState) => {
      const updatedState = {
        ...prevState,
        [data.element.id]: prevState[data.element.id].filter(
          (file) => file !== deleteFileName
        ),
      };
      return updatedState;
    });

    let mimeTypes =
      formData[data.element.id]?.mimeType &&
      formData[data.element.id]?.mimeType !== "NA"
        ? formData[data.element.id]?.mimeType
        : "";
    mimeTypes = mimeTypes
      .split(",")
      .filter((file, i) => i !== index)
      .join(",");

    handleFormDataChange(
      toSaveHashedFiles,
      fileNames,
      data.element.id,
      mimeTypes
    );
  };

  const acceptTypes = data?.element?.fileOptions?.allowedFileTypes
    ? data.element.fileOptions.allowedFileTypes
        .map((value) => {
          switch (value) {
            case "doc":
              return ".doc,.docx";
            case "image":
              return "image/*";
            case "pdf":
              return ".pdf";
            case "spreadSheet":
              return ".xls,.xlsx,.csv";
            case "vid":
              return "video/*";
            case "all":
              return "*.*";
            default:
              return "";
          }
        })
        .join(",")
    : null;

  return (
    <Box key={uuidv4()}>
      <Input
        id={data.element.id}
        onChange={(e) => {
          handleFileChange(e);
        }}
        style={{ display: "none" }}
        type="file"
        accept={acceptTypes ? acceptTypes : "image/*"}
        multiple
      />
      <HStack
        style={{
          width: "100%",
          border: "2px dashed #E2E8F0",
          borderRadius: "8px",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          padding: "20px 40px 40px 40px",
          position: "relative",
        }}
        onClick={() => {
          handleInputClick(data.element.id);
        }}
      >
        <VStack align="center">
          <Box
            style={{
              padding: "8px",
              border: "1px solid #EDF2F7",
              borderRadius: "8px",
            }}
          >
            <FiUploadCloud size="24px" color="#718096" />
          </Box>
          <Text
            fontSize="10px"
            fontWeight="400"
            color="#718096"
            lineHeight="1.4"
          >
            {`Size limit: ${data?.element?.fileOptions?.maxFileSize || 10} mb`}
          </Text>
          <Text
            fontSize="10px"
            fontWeight="400"
            color="#718096"
            lineHeight="1.4"
            w={isMobileScreen ? "100%" : "442px"}
            textAlign="center"
          >
            {acceptTypes && acceptTypes === "*.*"
              ? ".doc, .docx, .txt, .rtf, .odt, .ppt, .pptx, .odp, .ods, .csv, .xls,.xlsx, .nymbers, .key, .png, .jpg, .gif, .json, .xml, .zip, .rar,.mp3, .wav, .aiff, .pbix, .pdf"
              : acceptTypes || "image/*"}
          </Text>
        </VStack>
      </HStack>
      {/* Below if else statement is temporary until the preview for all files is handled */}
      {filesForDisplay && filesForDisplay.length > 0 && acceptTypes ? (
        acceptTypes === "image/*" ? (
          <HStack
            w="100%"
            justify="space-between"
            align="center"
            style={{
              display: "box",
              flexWrap: "wrap",
              padding: "6px 12px",
              borderRadius: "8px",
              margin: "14px auto",
              justifyContent: "flex-start",
            }}
            position="relative"
          >
            {filesForDisplay.map((fileName, index) => (
              <HStack className="file-upload-box" key={fileName}>
                <Image
                  boxSize="100%"
                  height="94px"
                  objectFit="cover"
                  src={
                    selectUploadedFiles && selectUploadedFiles[data.element.id]
                      ? selectUploadedFiles[data.element.id][fileName]
                      : ""
                  }
                  onClick={() =>
                    openImageInNewTab(
                      selectUploadedFiles[data.element.id][fileName]
                    )
                  }
                  borderRadius="8px"
                  border="1px solid #cbd5e0"
                />
                <span
                  style={{
                    padding: "6px",
                    cursor: "pointer",
                    position: "absolute",
                    right: "-15px",
                    top: "-15px",
                  }}
                >
                  {selectFilesUploadLoader[fileName] &&
                  !selectUploadedFiles[data.element.id][fileName] ? (
                    <Loader size={"24px"} />
                  ) : (
                    <span className="close-button">
                      <Image
                        src={closeBtn}
                        onClick={() => handleFileDelete(index, fileName)}
                      />
                    </span>
                  )}
                </span>
              </HStack>
            ))}
          </HStack>
        ) : (
          filesForDisplay.map((file, index) => (
            <HStack
              w="100%"
              justify="space-between"
              align="center"
              key={index}
              style={{
                padding: "6px 12px",
                background: "#F7FAFC",
                borderRadius: "8px",
                margin: "14px auto",
              }}
            >
              <HStack>
                <FiFile size="22px" color="#718096" />
                <Text
                  color="#4A5568"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="1.4"
                >
                  {file?.length > 18
                    ? `${file.slice(0, 18)}...${file.slice(file.length - 6)}`
                    : file}
                </Text>
              </HStack>
              <span
                style={{ padding: "6px", cursor: "pointer" }}
                onClick={() => handleFileDelete(index, file)}
              >
                {selectFilesUploadLoader[file] ? (
                  <Loader size={"24px"} />
                ) : (
                  <IoCloseCircleOutline size="24px" color="#718096" />
                )}
              </span>
            </HStack>
          ))
        )
      ) : null}
    </Box>
  );
}
