import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, sliceKey, reducer } from "../../../slice";
import * as selectors from "../../../selectors";
import { Box } from "@chakra-ui/react";
import { capitalizeStringUpdated } from "utils/commonFunctions";

const Address = ({ customerId }) => {
  const [customerAddress, setCustomerLink] = useState(null);
  const dispatch = useDispatch();
  const CustomersAddresses = useSelector(selectors.selectCustomerAddress);
  useEffect(() => {
    dispatch(actions.fetchCustomerAddresses({ customerId }));
  }, [customerId]);
  useEffect(() => {
    if (CustomersAddresses && CustomersAddresses[customerId]) {
      CustomersAddresses[customerId].forEach((address) => {
        if (address.isRegistered) {
          setCustomerLink(
            address.city ? address.city + ", " + address.state : "-"
          );
        }
      });
    }
  }, [CustomersAddresses]);

  return <Box>{customerAddress || "-"}</Box>;
};

export default Address;
