import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import { actions } from "./slice.js";
import * as API from "../../../../services/apis.js";


export function* handleFetchedTaskData({ payload }) {
    try {
        const res = yield call(API.getTaskData, payload);
        const { data } = res;
        const taskDataWithAvatars = yield all(
            data.payload.map(function* (task) {
                let taskWithAvatar = task;
                if (task.avatar && task.avatar.split() != "" && task.avatar != null) {
                    const res = yield call(API.getPreDefinedURL, task.avatar);
                    const { data } = res;
                    taskWithAvatar = { ...task, avatar: data.payload.url };
                }

                return taskWithAvatar;
            })
        );
        yield put(actions.fetchTaskDataSuccess(taskDataWithAvatars));
    } catch (err) {
        yield put(actions.fetchTaskDataFailure(err?.response?.data));
    }
}

const downloadFile = (url) => {
    fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/pdf",
        },
    })
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "file.pdf");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        .catch((error) => {
            console.error("Error downloading file:", error);
        });
};

export function* handleReport({ payload }) {
    const reportRes = yield call(API.getInspectionReport, payload);
    const { data: reportsData } = reportRes;
    if (reportsData.payload.length > 0 && reportsData.payload[0].name.split() != "") {
        const res = yield call(API.fetchSASUrl, {
            source: { name: process.env.REACT_APP_SAS_URL_GENERATOR_SOURCE },
            blobName: reportsData.payload[0].name,
        });

        downloadFile(res.data.url);
        yield put(actions.fetchReportSuccess());
    } else {
        // alert("Report not available")
        yield put(actions.fetchReportFailure());
        payload.onFailure()
    }


    
}


export function* reportSaga() {
    yield takeLatest(actions.fetchReport.type, handleReport);
}
export function* taskDataSaga() {
    yield takeLatest(actions.fetchTaskData.type, handleFetchedTaskData);
}
