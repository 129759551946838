import React from 'react'
import { Card , Flex, Box} from '@chakra-ui/react'
const cardSectStyle= {

}
function CardSection({count, status, img, rt}) {
  return (
    
    <Card cursor="pointer" borderRadius="12px" h="64px" border="1px solid #EDF2F7" p="12px 16px 12px 16px"  boxShadow="0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 4px 6px -2px rgba(10, 78, 24, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.07), 0px 10px 15px -3px rgba(22, 109, 93, 0.0)">
      <Flex fontStyle="Noto Sans"  direction="row" justify="space-between" align="center">
        <Box>
          <Flex direction="row" align="center" gap="15px">
            <Box><img style={{width:"40px", height:"40px"}} src={img} alt="" /></Box>
            <Box fontWeight="500" fontSize="12px" color="#414942">{status}</Box>
        </Flex>
        </Box> 

      <Box>
      <Flex direction="row" align="center" gap="12px">
            <Box fontWeight="600" fontSize="14px" color="#191C1A">{count??0}</Box>
            <Box><img src={rt} alt="" /></Box>
        </Flex>
      </Box>

      </Flex>
    </Card>

  )
}

export default CardSection