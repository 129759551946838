import React from "react";
import { Select } from "@agnext/reactlib";
import _ from "lodash";

const options = [
  { label: "Counterfeit", value: "counterfeit" },
  { label: "Procurement", value: "procurement" },
  { label: "Rake", value: "rake" },
  { label: "Stock", value: "stock" },
  { label: "Warehouse", value: "warehouse" },
];

const getOption = (value) => {
  let el = {};
  options.forEach((option) => {
    if (option.value === value) {
      el = { ...option };
    }
  });
  return el;
};

export default function SelectInspectionType({
  option,
  onOptionChange,
  errorStyle,
}) {
  return (
    <Select
      value={option.value}
      options={_.orderBy(options, "value", "asc")}
      onChange={(e) => {
        onOptionChange(getOption(e.target.value));
      }}
      placeholder="Select Inspection Type"
      focusBorderColor="login.500"
      fontSize="16px"
      sx={errorStyle}
    />
  );
}
