import { createSlice } from "@reduxjs/toolkit";
import { generateUUId } from "utils/utils";

export const initialState = {
  loading: false,
  warehouseDetails: [],
  warehouseDetail: [],
  jobsList: [],
  job: [],
  jobLoading: false,
  userDetails: [],
  userDetailsError: null,
  workflowList: [],
  workflowData: null,
  tasks: [],
  inspection: null,
  userDetail: [],
  userProfileImgUrls: [],
  allJobs: [],
  allTasks: [],
  userDetailsArr: [],
  userAvatarUrlsArr: [],
  customerUsers: [],
  clientsOfCustomer: [],
  workflow: {},
  sections: [],
  blocks: [],
  composites: [],
  components: [],
  reviewData: [],
  inspectionDetails: [],
  formTaskDetails: {},
  avatarUrl: null,
  creatingJob: false,
  updatingJob: false,
  loadingUserDetails: null,
  clientDetails: [],
  inspectionReportDetails: [],
  jobCount: null,
  trackLocation: [],
  locationLoading: null,
};

const jobsManagementSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    fetchWarehouseDetailsRequest: (state, action) => {
      state.loading = true;
    },
    fetchWorkflowDetailsSuccess: (state, action) => {
      state.loading = false;
      state.warehouseDetails = action.payload;
    },
    fetchWorkflowDetailsFaliure: (state, aciton) => {
      state.loading = false;
    },
    fetchWarehouseDetailsById: (state, action) => {
      state.loading = true;
    },
    fetchWarehouseDetailsByIdSuccess: (state, action) => {
      state.loading = false;
      state.warehouseDetail = action.payload;
    },
    fetchWarehouseDetailsByIdFaliure: (state, aciton) => {
      state.loading = false;
    },
    fetchJob: (state, action) => {
      state.jobLoading = true;
    },
    fetchJobSuccess: (state, action) => {
      state.jobLoading = false;
      state.job = action.payload;
    },
    fetchJobFaliure: (state, aciton) => {
      state.jobLoading = false;
    },
    fetchCreateInspectionRequest: (state, action) => {
      state.loading = true;
      state.creatingJob = true;
    },
    fetchCreateInspectionSuccess: (state, action) => {
      state.loading = false;
    },
    fetchCreateInspectionFailure: (state, action) => {
      state.loading = false;
      state.creatingJob = false;
    },
    fetchCreateJobRequest: (state, action) => {
      state.loading = true;
    },
    fetchCreateJobSuccess: (state, action) => {
      state.loading = false;
    },
    fetchCreateJobFailure: (state, action) => {
      state.loading = false;
      state.creatingJob = false;
    },
    fetchWorkfkowListRequest: (state, action) => {
      state.loading = true;
    },
    fetchWorkfkowListSuccess: (state, action) => {
      state.loading = false;
      state.workflowList = action.payload;
    },
    fetchWorkfkowListFailure: (state, action) => {
      state.loading = false;
    },
    fetchJobsListRequest: (state, action) => {
      state.loading = true;
    },
    fetchJobsListSuccess: (state, action) => {
      state.loading = false;
      state.jobsList = action.payload;
    },
    fetchJobsListFailure: (state, action) => {
      state.loading = false;
    },
    fetchUserDetailsRequest(state) {
      state.loading = true;
      state.loadingUserDetails = true;
      state.userDetailsError = null;
    },
    fetchUserDetailsSuccess(state, action) {
      state.loading = false;
      state.loadingUserDetails = false;
      state.userDetails = action.payload;
    },
    fetchUserDetailsFaliure(state, action) {
      state.loading = false;
      state.loadingUserDetails = false;

      state.userDetailsError = action.payload;
    },
    fetchUserDetail(state) {
      state.loading = true;
    },
    fetchUserDetailSuccess(state, action) {
      state.loading = false;
      state.userDetail = action.payload;
    },
    fetchUserDetailFailure(state, action) {
      state.loading = false;
    },

    fetchClientDetails(state) {
      state.loading = true;
    },

    fetchWorkflowDataRequest: (state, action) => {
      state.loading = true;
    },
    fetchWorkflowDataSuccess: (state, action) => {
      state.loading = false;
      state.workflowData = action.payload;
    },
    fetchWorkflowDataFailure: (state, action) => {
      state.loading = false;
    },

    fetchPostTasksRequest: (state, action) => {
      state.loading = true;
    },
    fetchPostTasksSuccess: (state, action) => {
      state.loading = false;
      state.creatingJob = false;
    },
    fetchPostTasksFailure: (state, action) => {
      state.loading = false;
      state.creatingJob = faslse;
    },
    fetchGetTasksRequest: (state, action) => {
      state.loading = true;
    },
    fetchGetTasksSuccess: (state, action) => {
      state.loading = false;
      state.tasks = action.payload;
      const taskForm = action.payload.reduce((acc, task) => {
        acc[task.formId] = task.id;
        return acc;
      }, {});
      state.formTaskDetails = taskForm;
    },
    fetchGetTasksFailure: (state, action) => {
      state.loading = false;
      state.tasks = action.payload;
    },
    fetchGetTasksFailure: (state, action) => {
      state.loading = true;
    },
    fetchUserProfileImgURL: (state, action) => {
      state.loading = true;
    },
    fetchUserProfileImgSuccess: (state, action) => {
      state.loading = false;
      state.userProfileImgUrls = action.payload;
    },
    fetchUserProfileImgFailure: (state, action) => {
      state.loading = false;
    },
    updateJob: (state, action) => {
      state.loading = true;
    },
    updateJobSuccess: (state, action) => {
      state.loading = false;
    },
    updateJobFailure: (state, action) => {
      state.loading = false;
    },
    deleteJob: (state, action) => {
      state.loading = true;
    },
    deleteJobSuccess: (state, action) => {
      state.loading = false;
    },
    deleteJobFailure: (state, action) => {
      state.loading = false;
    },
    getAllJobs: (state, action) => {
      state.loading = true;
    },
    getAllJobsSuccess: (state, action) => {
      state.loading = false;
      state.allJobs = action.payload.payload;
      state.jobCount = action.payload.otherParams;
    },
    getAllJobsFailure: (state, action) => {
      state.loading = false;
    },
    fetchGetInspectionRequest: (state, action) => {
      state.loading = true;
    },
    fetchGetInspectionSuccess: (state, action) => {
      state.loading = false;
      state.inspection = action.payload;
    },
    fetchGetInspectionFailure: (state, action) => {
      state.loading = false;
    },
    fetchUpdateTaskRequest: (state, action) => {
      state.loading = true;
    },
    fetchUpdateTaskSuccess: (state, action) => {
      state.loading = false;
    },
    fetchUpdateTaskFailure: (state, action) => {
      state.loading = false;
    },
    fetchDeleteTasksRequest: (state, action) => {
      state.loading = true;
      state.updatingJob = true;
    },
    fetchDeleteTasksSuccess: (state, action) => {
      state.loading = false;
    },
    fetchDeleteTasksFailure: (state, action) => {
      state.loading = false;
      state.updatingJob = false;
    },
    fetchUpdateJobRequest: (state, action) => {
      state.loading = true;
      state.updatingJob = true;
    },
    fetchUpdateJobSuccess: (state, action) => {
      state.loading = false;
      state.updatingJob = false;
    },
    fetchUpdateJobFailure: (state, action) => {
      state.loading = false;
      state.updatingJob = false;
    },
    fetchUpdateInspectionRequest: (state, action) => {
      state.loading = true;
      state.updatingJob = true;
    },
    fetchUpdateInspectionSuccess: (state, action) => {
      state.loading = false;
    },
    fetchUpdateInspectionFailure: (state, action) => {
      state.loading = false;
      state.updatingJob = false;
    },
    getMultipleTasksOfJob: (state, action) => {
      state.loading = true;
    },
    getMultipleTasksOfJobSuccess: (state, action) => {
      state.loading = false;
      state.allTasks = action.payload;
    },
    getMultipleTasksOfJobFailure: (state, action) => {
      state.loading = false;
    },
    fetchMultipleUserDetails: (state, action) => {
      state.loading = true;
    },
    fetchMultipleUserDetailsSuccess: (state, action) => {
      state.loading = false;
      state.userDetailsArr = action.payload;
    },
    fetchMultipleUserDetailsFailure: (state, action) => {
      state.loading = false;
    },
    fetchMultipleAvatarUrls: (state, action) => {
      state.loading = true;
    },
    fetchMultipleAvatarUrlsSuccess: (state, action) => {
      state.loading = false;
      state.userAvatarUrlsArr = action.payload;
    },
    fetchMultipleAvatarUrlsFailure: (state, action) => {
      state.loading = false;
    },
    fetchCustomerUsers: (state, action) => {
      state.loading = true;
    },
    fetchCustomerUsersSuccess: (state, action) => {
      state.loading = false;
      state.customerUsers = action.payload;
    },
    fetchCustomerUsersFailure: (state, action) => {
      state.loading = false;
    },
    fetchClientsOfCustomer: (state, action) => {
      state.loading = true;
    },
    fetchClientsOfCustomerSuccess: (state, action) => {
      state.loading = false;
      state.clientsOfCustomer = action.payload;
    },
    fetchClientsOfCustomerFailure: (state, action) => {
      state.loading = false;
    },
    fetchFormData: (state, action) => {
      state.loading = true;
    },
    fetchFormDataSuccess: (state, action) => {
      state.loading = false;
      // let newWorkflow = action.payload.form;
      // const newWorkflow = Object.assign({}, action.payload.form);
      let newWorkflow = JSON.parse(JSON.stringify(action.payload.form));
      newWorkflow["inspectionId"] = generateUUId();
      state.workflow = [newWorkflow];
      state.sections = action.payload.sections;
      state.blocks = action.payload.blocks;
      state.composites = action.payload.composites;
      state.components = action.payload.components;
    },
    fetchFormDataFailure: (state, action) => {
      state.loading = false;
    },
    fetchReviewData: (state, action) => {
      state.loading = true;
    },
    fetchReviewDataSuccess: (state, action) => {
      state.loading = false;
      state.reviewData = action.payload;
    },
    fetchReviewDataFailure: (state, action) => {
      state.loading = false;
    },
    fetchInspectionDetails: (state, action) => {
      state.loading = true;
    },
    fetchInspectionDetailsSuccess: (state, action) => {
      state.loading = false;
      state.inspectionDetails = action.payload;
    },
    fetchInspectionDetailsFailure: (state, action) => {
      state.loading = false;
    },
    fetchAvatarURL: (state, action) => {
      state.loading = true;
    },
    getAvatarURLSuccess: (state, action) => {
      state.loading = false;
      state.avatarUrl = action.payload;
    },
    getAvatarURLFailure: (state, action) => {
      state.loading = false;
    },
    fetchInspectionReport: (state, action) => {
      state.loading = true;
    },
    fetchInspectionReportSuccess: (state, action) => {
      state.loading = false;
      state.inspectionReportDetails = action.payload;
    },
    fetchInspectionReportFailure: (state, action) => {
      state.loading = false;
    },
    downloadReport(state, action) {
      state.reportLoader = true;
    },
    downloadReportSuccess(state, action) {
      state.reportLoader = false;
    },
    downloadReportFailure(state, action) {
      state.reportLoader = false;
    },

    fetchTrackLocation: (state, action) => {
      state.locationLoading = true;
    },
    fetchTrackLocationSuccess: (state, action) => {
      state.trackLocation = action.payload;
      state.locationLoading = false;
    },
    fetchTrackLocationsFailure: (state, action) => {
      state.locationLoading = false;
    },

    clearStates: (state, action) => {
      state.warehouseDetails = [];
      state.warehouseDetail = [];
      state.jobsList = [];
      state.job = [];
      state.userDetails = [];
      state.userDetailsError = null;
      state.workflowList = [];
      state.workflowData = null;
      state.tasks = [];
      state.inspection = null;
      state.userDetail = [];
      state.userProfileImgUrls = [];
      state.allJobs = [];
      state.allTasks = [];
      state.userDetailsArr = [];
      state.userAvatarUrlsArr = [];
      state.customerUsers = [];
      state.clientsOfCustomer = [];
      state.workflow = {};
      state.sections = [];
      state.blocks = [];
      state.composites = [];
      state.components = [];
      state.reviewData = [];
      state.inspectionDetails = [];
      state.formTaskDetails = {};
      state.avatarUrl = null;
      state.creatingJob = false;
      state.updatingJob = false;
      state.loadingUserDetails = null;
      state.trackLocation = [];
    },
    clearReviewDetails(state, action) {
      state.inspectionDetails = [];
      state.workflowData = null;
      state.tasks = [];
      state.formTaskDetails = {};
    },
  },
});

export const { actions, reducer, name: sliceKey } = jobsManagementSlice;
