import React, { useEffect, useState } from "react";
import { SelectWithSearch } from "@agnext/reactlib";

export default function DropdownWithSearch({
  data,
  formData,
  handleFormDataChange,
}) {
  const [optionValue, setOptionValue] = useState(
    formData[data.element.id]?.value || ""
  );
  useEffect(() => {
    if (optionValue) {
      handleFormDataChange(optionValue, "", data.element.id, "NA");
    }
  }, [optionValue, data.element.id]);

  useEffect(() => {
    setOptionValue(formData[data.element.id]?.value);
  }, [data.element.id, formData]);

  return (
    <>
      {data.element &&
      data.element.properties &&
      data.element.properties.options ? (
        <SelectWithSearch
          value={optionValue}
          options={data.element.properties.options}
          onChange={(val) => setOptionValue(val)}
          w="100%"
          h="40px"
          p="8px 12px"
          borderColor="#E2E8F0"
          borderRadius="8px"
          fontSize="12px"
          fontColor="#5D6470"
          placeholderColor="#718096"
          placeholder="Select or Search Option"
        />
      ) : (
        <></>
      )}
    </>
  );
}
