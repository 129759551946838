import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Text, 
  Flex, 
  Icon,
  SlideFade,
  Button,
  CloseButton
} from '@chakra-ui/react';
import { FiWifiOff, FiWifi, FiRefreshCw } from 'react-icons/fi';

const NetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showMessage, setShowMessage] = useState(false);
  const [showReloadMessage, setShowReloadMessage] = useState(false);
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
        showReloadMessageWithCountdown();
      }, 2000);
    };

    const showReloadMessageWithCountdown = () => {
      setShowReloadMessage(true);
      setCountdown(10);
    };

    const handleOffline = () => {
      setIsOnline(false);
      setShowMessage(true);
      setShowReloadMessage(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    let timer;
    if (showReloadMessage && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      window.location.reload();
    }
    return () => clearTimeout(timer);
  }, [showReloadMessage, countdown]);

  const bgColor = isOnline ? '#1c732e' : 'orange';
  const textColor = 'white';

  const handleDismiss = () => {
    setShowReloadMessage(false);
  };

  return (
    <>
      {showMessage && (
        <SlideFade in={showMessage} offsetY="-20px">
          <Box
            position="fixed"
            top={0}
            left="50%"
            transform="translateX(-50%)"
            bg={bgColor}
            color={textColor}
            py={2}
            px={6}
            boxShadow="lg"
            zIndex="2"
            w={'100%'}
          >
            <Flex alignItems="center">
              <Text fontWeight="bold" fontSize="15px" m={'auto'}>
                <Icon 
                  as={isOnline ? FiWifi : FiWifiOff} 
                  mr={2} 
                  fontSize="15px"
                  m={'auto'}
                  w={30}
                />
                {isOnline ? 'Online' : 'Offline'}
              </Text>
            </Flex>
          </Box>
        </SlideFade>
      )}
      
      {showReloadMessage && (
        <SlideFade in={showReloadMessage} offsetY="-20px">
          <Box
            position="fixed"
            top={0}
            left="50%"
            transform="translateX(-50%)"
            bg="#3182ce"
            color={textColor}
            py={2}
            px={6}
            zIndex="2"
            w={'100%'}
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontWeight="bold" fontSize="15px" textAlign={'center'}>
                <Icon 
                  as={FiRefreshCw} 
                  mr={2} 
                  fontSize="15px"
                  w={30}
                />
                Content updated. Page will reload in {countdown} seconds.
              </Text>
              <CloseButton size="sm" onClick={handleDismiss} />
            </Flex>
          </Box>
        </SlideFade>
      )}
    </>
  );
};

export default NetworkStatus;