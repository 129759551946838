import React from "react";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { useDispatch, useSelector } from "react-redux";
import { actions, sliceKey, reducer } from "../slice";
import { reportSaga} from "../saga";
import * as selectors from "../selectors";
import { Link, useHistory } from "react-router-dom";
import { Box, Flex, Heading, Text, Badge, Image, Stack, Button, useToast } from "@chakra-ui/react";
import { ReactComponent as Location } from "../../../../../assets/svg/locationService.svg";
import { ReactComponent as Copy } from "../../../../../assets/svg/copy.svg";
import {Loader} from "../../../../../components/Loader/Loader"
import Critical from "../../../../../assets/svg/Critical.svg";
import Low from "../../../../../assets/svg/Low.svg";
import High from "../../../../../assets/svg/High.svg";
import Medium from "../../../../../assets/svg/Medium.svg";
import avatarDefault from "../../../../../assets/svg/avatarDefault.svg";

function DetailInspectionCard({ dueDate, assignedDate, dayLeft, inspectionData }) {
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: reportSaga });

    const dispatch = useDispatch();
    const history = useHistory();
    const toast = useToast()
    
    const loadReport = useSelector(selectors.reportLoading);
    const reportData = useSelector(selectors.reportData)

    const handleInspection =()=>{
        history.push(`/mobile/auth/form-view/${inspectionData.formId}?taskId=${inspectionData.id}&inspectionId=${inspectionData.inspectionId}&jobId=${inspectionData.jobid}`)
    }

    const handleReport =()=>{
       dispatch(actions.fetchReport({inspectionId: inspectionData.inspectionId, inspectionDetails: inspectionData, onSuccess: ()=>{

       }, onFailure: ()=>{
        toast({
            title: 'Inspection Report',
            description: "Report not available ",
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
       }}))
    }

    let priorityImg;
    switch (inspectionData.priority) {
        case "high":
            priorityImg = High;
            break;
        case "critical":
            priorityImg = Critical;
            break;
        case "medium":
            priorityImg = Medium;
            break;
        case "low":
            priorityImg = Low;
            break;
        default:
            priorityImg = Medium;
    }

    return (
        <Box p={"15px 0px"} sx={{ fontFamily: "Roboto" }}>
            <Flex justify="space-between" alignItems="center" mb={10}>
                <Flex mt={"16px"} justify={"space-around"} align={"center"} gap={"10px"}>
                    <Box width="40px" height="40px" borderRadius="4px" overflow="hidden" flexShrink={0} backgroundColor="#f7f9fb" p={"1px"}>
                        <Image width="100%" height="100%" objectFit="cover" alt="" src={inspectionData.avatar || avatarDefault} />
                    </Box>
                    <Flex flex={1} flexDirection="column" alignItems="flex-start" justifyContent="flex-start" textAlign="left" fontSize="16px" color="#2d3748">
                        <Text textDecoration="none" alignSelf="stretch" letterSpacing="0.15px" lineHeight="24px" fontWeight="500" color="inherit" fontSize={"16px"}>
                            {inspectionData.clientOrgName}
                        </Text>
                        <Text alignSelf="stretch" fontSize="12px" lineHeight="16px" color="#718096" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                            {inspectionData.category.type || "NA"}
                        </Text>
                    </Flex>
                </Flex>

                <Flex align={"center"} justify={"space-around"} gap={"10px"}>
                    <Image height="10px" width="15px" overflow="hidden" flexShrink={0} objectFit="cover" minHeight="18px" loading="lazy" alt="" src={priorityImg} />
                    <Text fontSize={"12px"}>{inspectionData.priority}</Text>
                </Flex>
            </Flex>
            <Stack spacing={4}>
                <Box borderBottom={"2px solid #f5f5f5"} pb={"15px"}>
                    <Flex align={"center"} justify={"space-between"}>
                        <Box>
                            <Heading size="sm" mb={2} sx={{ fontWeight: "400", fontSize: "16px", color: "#191C1A" }}>
                                Due date
                            </Heading>
                            <Text fontSize={"14px"}> {dueDate}</Text>
                        </Box>
                        <Box>
                            <Text fontSize="sm" color="gray.500">
                                {inspectionData.status === "todo" || inspectionData.status === "inprogress" ? (
                                    dayLeft < 0 ? (
                                        <Text fontSize={"12px"} color={"red"}>
                                            overdue
                                        </Text>
                                    ) : dayLeft == 0 ? (
                                        <Text fontSize={"12px"} color={"#dbdb25"}>
                                            today
                                        </Text>
                                    ) : (
                                        <Text fontSize={"12px"} color={"green"}>
                                            {" "}
                                            {dayLeft} days left
                                        </Text>
                                    )
                                ) : null}
                            </Text>
                        </Box>
                    </Flex>
                </Box>
                <Box borderBottom={"2px solid #f5f5f5"} pb={"15px"}>
                    <Flex justify={"space-between"} align={"center"}>
                        <Box>
                            <Heading size="sm" mb={2} sx={{ fontWeight: "400", fontSize: "16px", color: "#191C1A" }}>
                                Location
                            </Heading>
                            <Text fontSize={"14px"}>{inspectionData.origin.address.addressLine1}</Text>
                        </Box>
                        <Box>
                            <Location />
                        </Box>
                    </Flex>
                </Box>
                <Box borderBottom={"2px solid #f5f5f5"} pb={"15px"}>
                    <Heading size="sm" mb={2} sx={{ fontWeight: "400", fontSize: "16px", color: "#191C1A" }}>
                        Inspection assigned on
                    </Heading>
                    <Text fontSize={"14px"}>{assignedDate}</Text>
                </Box>
                <Box>
                    <Flex justify={"space-between"} align={"center"}>
                        <Box>
                            <Heading size="sm" mt={2} mb={2} sx={{ fontWeight: "400", fontSize: "16px", color: "#191C1A" }}>
                                Inspection ID
                            </Heading>
                            <Text fontSize={"14px"}>{inspectionData.inspectionId}</Text>
                        </Box>
                        <Box>
                            <Copy />
                        </Box>
                    </Flex>
                </Box>
        
                    {inspectionData.status == 'todo' ? <Button mt={"25px"} colorScheme="green" onClick={handleInspection}>Start Inspection</Button>: inspectionData.status == 'inprogress'? <Button mt={"25px"} colorScheme="green" onClick={handleInspection}>Resume Inspection</Button>: <Button mt={"25px"} colorScheme="green" onClick={handleReport}> {loadReport? <Loader position={'center'} margin={'auto'} style={{height:"3px"}}/>: "Download Report"}</Button> } 
             
            </Stack>
        </Box>
    );
}

export default DetailInspectionCard;
