import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  form: [],
  sections: [],
  blocks: [],
  composites: [],
  components: [],
  reviewData: [],
  taskDetail: [],
  locationTrackFlag: false,
  filesUploadLoader: {},
  filesUploadError :{},
  urlGenerated: null
};

const homePage = createSlice({
  name: "homePage",
  initialState,
  reducers: {
    // Fetch Data
    fetchFormData: (state, action) => {
      state.loading = true;
    },
    fetchFormDataSuccess: (state, action) => {
      state.loading = false;
      state.form = [JSON.parse(JSON.stringify(action.payload.form))];
      state.sections = action.payload.sections;
      state.blocks = action.payload.blocks;
      state.composites = action.payload.composites;
      state.components = action.payload.components;
    },
    fetchFormDataFailure: (state, action) => {
      state.loading = false;
    },

    // post
    postFormData: (state, action) => {
      state.loading = true;
    },

    postFormDataSuccess: (state, action) => {
      state.loading = false;
    },

    postFormDataFailure: (state, action) => {
      state.loading = false;
      // state.error = action.payload;
    },
    fetchReviewData: (state, action) => {
      state.loading = true;
    },
    fetchReviewDataSuccess: (state, action) => {
      state.loading = false;
      state.reviewData = action.payload;
    },
    fetchReviewDataFailure: (state, action) => {
      state.loading = false;
    },
    fetchSingleTask: (state, action) => {
      state.loading = true;
    },
    fetchSingleTaskSuccess: (state, action) => {
      state.loading = false;
      state.taskDetail = action.payload;
    },
    fetchSingleTaskFailure: (state, action) => {
      state.loading = false;
    },
    fetchLocation: (state, action) => {
      state.locationTrackFlag = true;
    },
    fetchLocationSuccess: (state, action) => {
      state.locationTrackFlag = true
    },
    fetchLocationFailure: (state, action) => {
      state.locationTrackFlag = false
    },
    postFileData: (state, action) => {
      const { fileName } = action.payload;
      if (!state?.filesUploadLoader) {
        state.filesUploadLoader = {};
      }
      state.filesUploadLoader[fileName] = true;
    },
    postFileDataSuccess: (state, action) => {
      const { fileName } = action.payload;
      state.filesUploadLoader[fileName] = false;
    },
    postFileDataFailure: (state, action) => {
      const { fileName } = action.payload.payload;
      state.filesUploadLoader[fileName] = false;
    },
       
    createPreview: (state, actions) => {
      // dispatched when form is submitted successfully!
    }
  },
});

export const { actions, reducer, name: sliceKey } = homePage;
