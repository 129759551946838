import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, sliceKey, reducer } from "../../../slice";
import * as selectors from "../../../selectors";

const Email = ({ customerId }) => {
  const [customerLink, setCustomerLink] = useState(null);
  const dispatch = useDispatch();
  const customersLinks = useSelector(selectors.selectAllCUstomerLinks);

  useEffect(() => {
    dispatch(actions.getCustomerLinks({ customerId }));
  }, [customerId]);
  useEffect(() => {
    if (customersLinks && customersLinks[customerId]) {
      customersLinks[customerId]?.forEach((link) => {
        if (link.linkType === "email") {
          setCustomerLink(link.value);
        }
      });
    }
  }, [customersLinks]);

  return <div>{customerLink || "-"}</div>;
};

export default Email;
