import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { actions } from "./slice.js";
import * as API from "../../../../services/apis.js";


export function* handleFetchedTaskData({payload}){
    try{
        const res = yield call(API.getTaskAnalytics,payload.payload);
        const {data} = res;
        yield put(actions.fetchTaskAnalyticsDataSuccess(data.payload))
    }
    catch(err) {
        yield put(actions.fetchTaskAnalyticsDataFailure(err?.response?.data));
    }
}

export function* taskAnalyticsSaga() {
    yield takeLatest(actions.fetchTaskAnalyticsData.type, handleFetchedTaskData)
}