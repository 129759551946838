import { Table } from "@agnext/reactlib";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import * as selectors from "../../selectors";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { actions, reducer, sliceKey } from "../../slice";
import { collateralManagerSaga } from "../../saga";
import { Box, HStack, Select, VStack, Text, Tooltip } from "@chakra-ui/react";
import WarehouseScore from "views/Dashboard/Analytics Dashboard/components/WarehouseScore";
import { format, sub } from "date-fns";
import { groupByKey } from "utils/commonFunctions";
import { CmHeader } from "./variables/variable";
import {
  capitalizeString,
  getUniqueObjectsByProperty,
} from "utils/commonFunctions";
import { PopupTable } from "../../components/PopupTable";
import StateDropdown from "views/shared/StateDropdown";
import { ErrorBoundary } from "react-error-boundary";
import FallbackToast from "errorsFallback/FallbackToast";
import FallbackUI from "errorsFallback/FallbackUI";
const CmTableComponent = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: collateralManagerSaga });
  const [stateCmData, setCmData] = useState([]);

  const [selectedOption, setSelectedOption] = useState("Top 7");
  const [selectedDateType, setSelectedDateType] = useState("year");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [date, setDate] = useState({
    fromDate: sub(new Date(), { months: 12 }),
    toDate: new Date(),
  });
  const { fromDate, toDate } = date;

  const dispatch = useDispatch();

  const handleDateChange = (fromDate, toDate) => {
    setDate({
      fromDate,
      toDate,
    });
  };

  let inspectionData = useSelector(selectors.selectInspectionData) || [];
  // Sort the array based on the 'score' key in ascending order
  let groupedInspectionData = groupByKey(inspectionData);
  //correct
  let data = groupedInspectionData.map((obj) => {
    // if(selectedLocation == "")
    return Object.values(obj);
  });
  data = data.flat();
  const resultArray = data.map((arrayOfObjects) => {
    const firstObject = arrayOfObjects[0];

    // const uniqueWarehouses = Array.from(
    //   new Set(arrayOfObjects.map((obj) => obj.originName))
    // );
    // const avgScore =
    //   arrayOfObjects.reduce(
    //     (sum, obj) => sum + parseFloat(Number(obj.warehouseScore)),
    //     0
    //   ) / arrayOfObjects.length;
    const uniqueWarehouses = selectedLocation
      ? Array.from(
          new Set(
            arrayOfObjects
              .filter((obj) => obj.state === selectedLocation) // Filter by selected state
              .map((obj) => obj.originName)
          )
        ) // Get unique warehouses for the selected state
      : Array.from(new Set(arrayOfObjects.map((obj) => obj.originName))); // Get unique warehouses for all warehouses

    const avgScore = selectedLocation
      ? arrayOfObjects
          .filter((obj) => obj.state === selectedLocation) // Filter by selected state
          .reduce(
            (sum, obj) => sum + parseFloat(Number(obj.warehouseScore)),
            0
          ) /
        arrayOfObjects.filter((obj) => obj.state === selectedLocation).length // Count only for the selected state
      : arrayOfObjects.reduce(
          (sum, obj) => sum + parseFloat(Number(obj.warehouseScore)),
          0
        ) / arrayOfObjects.length; // Calculate average score for all warehouses

    const resultObject = {
      cmName: capitalizeString(firstObject.cmName?.toLowerCase()),
      warehouseCount: uniqueWarehouses.length,
      avgScore: isNaN(Number(avgScore)) ? 0 : avgScore.toFixed(1),
      inspectionCount: arrayOfObjects.length,
      quantity: arrayOfObjects
        .reduce((sum, obj) => sum + obj.quantity, 0)
        .toFixed(2),
      bagCount: arrayOfObjects.reduce(
        (sum, obj) => sum + parseInt(Number(obj.bagCount)),
        0
      ),
    };

    return resultObject;
  });
  let filteredArray = resultArray?.filter(
    (obj) =>
      obj.cmName !== null && obj.cmName !== undefined && obj.cmName !== ""
  );
  let cmData = filteredArray.sort(
    (a, b) => a.warehouseScore - b.warehouseScore
  );
  cmData = cmData?.map((obj, index) => {
    return {
      ...obj,
      cmName: <PopupTable name={obj.cmName} data={data[index]} />,

      avgScore: <WarehouseScore WHScore={obj?.avgScore} />,
    };
  });

  cmData = selectedOption === "Top 7" ? cmData : cmData.reverse();

  const handleDateType = (type) => {
    setSelectedDateType(type);
  };

  const toggleTopBottom = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectedOption = e.target.options[selectedIndex].label;
    setSelectedOption(selectedOption);
  };
  const styles = {
    select: {
      borderRadius: "8px",
      border: "1px solid #E2E8F0",
      background: "#F7FAFC",
      display: "flex",
      height: "32px",
      padding: " 6px 8px 6px 12px",
      justifyContent: "flex-end",
      alignItems: "flex-start",
      gap: "12px",
      color: "#4A5568",
      fontSize: "12.5px",
    },
    selectOpt: {
      color: "#2D3748",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "20px",
    },
    heading: {
      color: "#7E8DA2",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "20px",
    },
    headerBtn: {
      padding: "0px 12px",
      justifyContent: "center",
      height: "32px",
      alignItems: "center",
      border: "1px solid #EDF2F7",
      color: "#4A5568",
      fontFamilpy: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "20px",
      cursor: "pointer",
    },
  };
  function setDates(duration) {
    const today = new Date();
    let fromDate, toDate;

    switch (duration) {
      case "day":
        fromDate = toDate = sub(today, { days: 1 });
        break;
      case "week":
        fromDate = sub(today, { days: 7 });
        toDate = today;
        break;
      case "month":
        fromDate = sub(today, { days: 30 });
        toDate = today;
        break;
      case "quarter":
        fromDate = sub(today, { months: 3 });
        toDate = today;
        break;
      case "year":
        fromDate = sub(today, { months: 12 });
        toDate = today;
        break;
      default:
        break;
    }

    handleDateChange(fromDate, toDate);
  }
  const uniqueObjects =
    getUniqueObjectsByProperty(inspectionData, "state") || [];
  const handleStateChange = (selectedState) => {
    setSelectedLocation(selectedState); // Update the selected state in the component state
    // Filter the inspectionData based on the selected state
    const filteredData = inspectionData.filter(
      (item) =>
        item.state === selectedState &&
        item.cmName !== null &&
        item.cmName !== undefined &&
        item.cmName !== ""
    );

    // Sort the filtered data by warehouse score
    const sortedData = filteredData.sort(
      (a, b) => a.warehouseScore - b.warehouseScore
    );
    // Update the cmData state with the sorted and filtered data
    setCmData(
      sortedData?.map((obj, index) => {
        return {
          ...obj,
          cmName: <PopupTable name={obj.cmName} data={data[index]} />,
          avgScore: <WarehouseScore WHScore={obj?.avgScore} />,
        };
      })
    );
  };
  useEffect(() => {
    // Prepare query paramenters to get filtered data from API
    const forQueryParams = {
      ...(fromDate &&
        toDate && {
          start_dt: format(fromDate, "yyyy-MM-dd"),
          end_dt: format(toDate, "yyyy-MM-dd"),
        }),
    };
    dispatch(
      actions.fetchInspectionData({
        payload: forQueryParams,
      })
    );
  }, [date, selectedLocation, stateCmData]);
  return (
    <Box mt={"75px"} w={"100%"} bg={"#fff"} borderRadius={"15px"}>
      <VStack w={"100%"}>
        <HStack justifyContent={"space-between"} w={"100%"} p={"15px"}>
          <Text sx={styles.heading}>Collateral Manager</Text>
          <HStack gap={"0px"}>
            {/* <Box display={{ base: "none", md: "block" }} marginRight={"5px"} flex="1">
              <StateDropdown
                options={uniqueObjects}
                placeholder={"Location"}
                handleFilterQuery={(selectedState) => handleStateChange(selectedState)}
                property={"location"}
                listKey={"state"}
              />
            </Box> */}
            <Box
              sx={styles.headerBtn}
              borderBottomLeftRadius="8px"
              borderTopLeftRadius="8px"
              as="button"
              backgroundColor={selectedDateType === "day" ? "#F7FAFC" : ""}
              onClick={() => {
                handleDateType("day");
                setDates("day");
              }}
            >
              <Tooltip label="Yesterday" hasArrow>
                D
              </Tooltip>
            </Box>
            <Box
              sx={{
                ...styles.headerBtn,
              }}
              as="button"
              backgroundColor={selectedDateType === "month" ? "#F7FAFC" : ""}
              onClick={() => {
                handleDateType("month");
                setDates("month");
              }}
            >
              <Tooltip label="Last Month" hasArrow>
                M
              </Tooltip>
            </Box>
            <Box
              sx={{
                ...styles.headerBtn,
              }}
              as="button"
              backgroundColor={selectedDateType === "quarter" ? "#F7FAFC" : ""}
              onClick={() => {
                handleDateType("quarter");
                setDates("quarter");
              }}
            >
              <Tooltip label="Last Quater" hasArrow>
                Q
              </Tooltip>
            </Box>
            <Box
              sx={{
                ...styles.headerBtn,
              }}
              as="button"
              borderBottomRightRadius="8px"
              borderTopRightRadius="8px"
              backgroundColor={selectedDateType === "year" ? "#F7FAFC" : ""}
              onClick={() => {
                handleDateType("year");
                setDates("year");
              }}
            >
              <Tooltip label="Last Year" hasArrow>
                Y
              </Tooltip>
            </Box>
          </HStack>

          {/* <Select sx={styles.select} onChange={toggleTopBottom} w={"100px"}>
            <option value="Top 7" sx={styles.selectOpt}>
              Top 7
            </option>
            <option value="Bottom" sx={styles.selectOpt}>
              Bottom 7
            </option>
          </Select> */}
        </HStack>
        <Table
          overflowY="auto"
          headerList={CmHeader}
          dataList={selectedLocation ? stateCmData : cmData}
          fixedHeader={true}
          containerStyle={{ width: "100%" }}
        />
      </VStack>
    </Box>
  );
};

const CmTable = () => {
  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <CmTableComponent />
    </ErrorBoundary>
  );
};

export default CmTable;
