import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import DropdownWithCheckboxesLatest from "components/DropdownWithCheckboxes/DropdownWithCheckBoxesLatest";
import React, { useEffect, useState } from "react";
import Select from "views/shared/Select";
import { LuUploadCloud } from "react-icons/lu";
import SelectWithIdLabel from "views/shared/SelectWithIdLabel";

const myStyles = {
  background: "white",
  boxShadow: "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
  borderRadius: 15,
};
const flexStyles = {
  gap: "18px",
  flexDirection: { base: "column", md: "row" },
  justifyContent: "space-between",
  width: "100%",
};

const CustomInput = ({
  label,
  placeholder,
  value,
  type,
  onChange,
  isValid,
  errorMessage,
  isDisabled,
}) => {
  return (
    <Flex direction="column" gap="8px" flex={{ base: "1", md: "1" }}>
      <Text fontSize={"12px"} fontWeight={"700"}>
        {label}
      </Text>
      <Input
        type={type || "text"}
        placeholder={placeholder}
        value={value}
        width="100%"
        alignSelf="stretch"
        focusBorderColor="#3182ce"
        onChange={(event) => onChange(event.target.value)}
        fontSize="14px"
        isDisabled={isDisabled}
      />
      {!isValid && (
        <Text fontSize="12px" color="red.500">
          {errorMessage}
        </Text>
      )}
    </Flex>
  );
};

const countryCodes = ["India +91", "UAE +971"];

const genders = ["male", "female", "other", "Prefer Not To Answer"];
const departments = ["Operations", "Management"];
const AddUserTop = ({
  selectedClient,
  clientId,
  handleChooseClient,
  isClientUser,
  handleSwitchClick,
  clientsOfCustomerDropdown,
  handleFileChange,
  profileImagePreview,
  userDetails,
  setUserDetails,
  allRolesForDropdown,
  setRegisteredAddress,
  selectedRoles,
  setSelectedRoles,
  countryCode,
  setCountryCode,
  emailIsValid,
  setEmailIsValid,
  isPhoneValid,
  setIsPhoneValid,
  setIsChanged,
  isChanged,
  isEditing,
}) => {
  const handleInputChange = (field, value) => {
    if (!isChanged) setIsChanged(true);
    setUserDetails((prevValues) => {
      return { ...prevValues, [field]: value };
    });
  };

  const handleOptionChange = (value, customKey) => {
    if (!isChanged) setIsChanged(true);
    if (customKey === "countryCode") {
      let code = !value ? null : value === "India +91" ? 91 : 971;
      setUserDetails((prevValues) => {
        return { ...prevValues, countryCode: code };
      });
      if (value) {
        setIsPhoneValid(validatePhone(userDetails?.mobile, value));
      } else {
        setIsPhoneValid(false);
      }
      setCountryCode(value);
    } else {
      setUserDetails((prevValues) => {
        return {
          ...prevValues,
          [customKey]: value,
        };
      });
    }
  };

  // useEffect(() => {
  //   if (countryCode) {
  //     let code = countryCode === "+91 India" ? 91 : 971;
  //     setUserDetails((prevValues) => {
  //       return { ...prevValues, countryCode: code };
  //     });
  //   }
  // }, [countryCode]);

  const handleInputClick = () => {
    if (!isChanged) setIsChanged(true);
    document.getElementById("input_profile_img").click();
  };

  const handleSelectChange = (itemId, property) => {
    if (!isChanged) setIsChanged(true);
    if (property === "Roles") {
      setSelectedRoles([itemId]);
    }
  };
  
  // this function will be used in future when roles dropdown is multiselect 
  // const handleSelection = (prevSelectedItems, itemId) => {
  //   if (!isChanged) setIsChanged(true);
  //   if (prevSelectedItems.includes(itemId)) {
  //     return prevSelectedItems.filter((id) => id !== itemId);
  //   } else {
  //     return [...prevSelectedItems, itemId];
  //   }
  // };

  const validateEmail = (email) => {
    if (!email) return true;
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  };
  const validatePhone = (phone, code) => {
    let tempCode = code ? code : countryCode;
    if (!phone) return true;
    if (!tempCode) return false;
    if (tempCode === "India +91") {
      // Indian mobile number validation
      const pattern = /^[6-9]\d{9}$/;
      return pattern.test(phone);
    } else if (tempCode === "UAE +971") {
      // UAE mobile number validation
      const pattern = /^[0-9]{9}$/;
      return pattern.test(phone);
    }
    return false;
  };
  return (
    <Stack spacing={"24px"}>
      <Text fontWeight={"600"}>Basic Info</Text>
      <Box sx={myStyles}>
        <Box padding={"16px 24px"}>
          <Flex gap={"32px"} direction={"column"}>
            <Flex justifyContent={"space-between"}>
              <Text
                sx={{ color: "#718096", fontSize: "14px", fontWeight: "600" }}
              >
                Roles
              </Text>
              {isEditing ? (
                <></>
              ) : (
                <Flex gap={"8px"} alignItems={"center"}>
                  <Text fontSize={"12px"} fontWeight={"400"}>
                    Client User?
                  </Text>

                  <Switch
                    size="sm"
                    isChecked={isClientUser}
                    onChange={handleSwitchClick}
                  />
                </Flex>
              )}
            </Flex>
            <Flex direction={"column"} gap={"18px"}>
              <Flex sx={flexStyles}>
                {isClientUser ? (
                  <>
                    <Flex
                      direction={"column"}
                      gap={"8px"}
                      flex={{ base: "1", md: "1" }}
                    >
                      <Text fontSize={"12px"} fontWeight={"700"}>
                        Clients*
                      </Text>
                      <SelectWithIdLabel
                        isDisabled={isEditing}
                        customKey="clientsOfCustomer"
                        options={clientsOfCustomerDropdown.sort((a, b) =>
                          a.label.localeCompare(b.label)
                        )}
                        placeholder="Select"
                        value={selectedClient}
                        handleOptionChange={handleChooseClient}
                      />
                    </Flex>
                  </>
                ) : (
                  <>
                    <Flex
                      direction={"column"}
                      gap={"8px"}
                      flex={{ base: "1", md: "1" }}
                    >
                      <Text fontSize={"12px"} fontWeight={"700"}>
                        Department*
                      </Text>
                      <Select
                        placeholder={"Select"}
                        customKey="department"
                        value={userDetails?.department}
                        handleOptionChange={handleOptionChange}
                        options={departments}
                      />
                    </Flex>
                    <CustomInput
                      label="Designation*"
                      placeholder="eg. Manager"
                      value={userDetails?.designation}
                      onChange={(value) =>
                        handleInputChange("designation", value)
                      }
                    />
                  </>
                )}

                <Flex
                  direction={"column"}
                  gap={"8px"}
                  flex={{ base: "1", md: "1" }}
                >
                  <Text fontSize={"12px"} fontWeight={"700"}>
                    Roles*
                  </Text>
                  <DropdownWithCheckboxesLatest
                    disable={isClientUser ? (clientId ? false : true) : false}
                    dropDownList={allRolesForDropdown}
                    property="Roles"
                    handleSelectChange={handleSelectChange}
                    title="Select"
                    flex="1"
                    selectedItems={selectedRoles}
                    setSelectedRoles={setSelectedRoles}
                    isForRoles={true}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Box sx={myStyles}>
        <Box padding={"16px 24px"}>
          <Flex gap={"32px"} direction={"column"}>
            <Text
              sx={{ color: "#718096", fontSize: "14px", fontWeight: "600" }}
            >
              User Details
            </Text>
            <Flex direction={{ base: "column", md: "row" }} gap={"32px"}>
              <Flex direction={"column"} gap={"12px"}>
                <Text fontSize={"12px"} fontWeight={"700"}>
                  Upload Logo
                </Text>
                <Box>
                  <Input
                    id="input_profile_img"
                    display={"none"}
                    type="file"
                    onChange={handleFileChange}
                  />
                  <Box
                    width="120px"
                    height="120px"
                    border="2px dashed #E2E8F0"
                    borderRadius="8px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    cursor="pointer"
                    onClick={handleInputClick}
                  >
                    {profileImagePreview ? (
                      <img
                        src={profileImagePreview}
                        alt="Profile Image"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <LuUploadCloud
                        size="24px"
                        color="#718096"
                        position={"absolute"}
                      />
                    )}
                  </Box>
                </Box>
              </Flex>
              <Flex direction={"column"} gap={"18px"} w="100%">
                <Flex sx={flexStyles}>
                  <CustomInput
                    label="First Name*"
                    placeholder="eg. Amit"
                    value={userDetails?.firstName}
                    onChange={(value) => handleInputChange("firstName", value)}
                  />
                  <CustomInput
                    label="Last Name*"
                    placeholder="eg. Singh"
                    value={userDetails?.lastName}
                    onChange={(value) => handleInputChange("lastName", value)}
                  />
                </Flex>
                <Flex sx={flexStyles}>
                  <Flex
                    direction={"column"}
                    gap={"8px"}
                    flex={{ base: "1", md: "1" }}
                  >
                    <Text fontSize={"12px"} fontWeight={"700"}>
                      Gender
                    </Text>
                    <Select
                      placeholder={"Select"}
                      customKey="gender"
                      value={userDetails?.gender}
                      handleOptionChange={handleOptionChange}
                      options={genders}
                    />
                  </Flex>
                  <CustomInput
                    label="Email*"
                    placeholder="eg. abc@gmail.com"
                    value={userDetails?.email}
                    onChange={(value) => {
                      handleInputChange("email", value);
                      handleInputChange("userName", value);
                      setEmailIsValid(validateEmail(value));
                    }}
                    isValid={emailIsValid}
                    errorMessage="Invalid email format"
                    isDisabled={isEditing}
                  />
                </Flex>
                <Flex sx={flexStyles}>
                  {/* <Flex
                    direction="column"
                    gap="8px"
                    flex={{ base: "1", md: "1" }}
                  >
                    <Text fontSize={"12px"} fontWeight={"700"}>
                      Country
                    </Text>
                    <Select
                      customKey="country"
                      options={countries}
                      placeholder="Select"
                      value={country}
                      handleOptionChange={handleOptionChange}
                    />
                  </Flex> */}

                  <Flex w="100%">
                    <Flex
                      direction="column"
                      gap="8px"
                      flex={{ base: "1", md: "1" }}
                    >
                      <Text fontSize={"12px"} fontWeight={"700"}>
                        Phone Number*
                      </Text>

                      <InputGroup>
                        <InputLeftAddon p={"0"} br={"2px"} border={"none"}>
                          <Select
                            placeholder="(+XX)"
                            customKey="countryCode"
                            value={countryCode}
                            handleOptionChange={handleOptionChange}
                            options={countryCodes}
                            isDisabled={isEditing}
                            minWidth="120px"
                            maxWidth="120px"
                          />
                        </InputLeftAddon>
                        <Flex w="100%">
                          <Input
                            isDisabled={isEditing}
                            type={"number"}
                            placeholder={
                              countryCode === "UAE +971"
                                ? "eg. 96 000 ####"
                                : "eg. 967 000 ####"
                            }
                            value={userDetails?.mobile}
                            width="100%"
                            alignSelf="stretch"
                            focusBorderColor="#3182ce"
                            onChange={(event) => {
                              setIsPhoneValid(
                                validatePhone(event.target.value)
                              );
                              handleInputChange("mobile", event.target.value);
                            }}
                            fontSize="14px"
                          />
                        </Flex>
                      </InputGroup>
                      {!isPhoneValid && (
                        <Text fontSize="12px" color="red.500">
                          {!countryCode
                            ? "Please select Country code"
                            : "Please enter valid mobile number"}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Stack>
  );
};

export default AddUserTop;
