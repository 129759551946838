import { Table } from "@agnext/reactlib";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import Eye from "assets/svg/eye-icon.svg";
import Edit from "assets/svg/edit-icon.svg";
import AvatarWithName from "views/jobs/components/viewJob/Table Components/AvatarWithName";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import { extractDayMonthYearFromTimestamp } from "utils/commonFunctions";
import Address from "./TableComponents/Address";
import Email from "./TableComponents/Email";

const Icons = ({ clientId, customerId }) => {
  return (
    <Flex gap={2}>
      <Link
        to={`/admin/clients/viewClient?clientId=${clientId}&customerId=${customerId}`}
      >
        <Box
          background={"#EDF2F7"}
          height={"40px"}
          width={"48px"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius={"12px"}
          style={{ cursor: "pointer" }}
        >
          <Image src={Eye} alt="" />
        </Box>
      </Link>
      <Link
        to={`/admin/clients/updateClient?clientId=${clientId}&customerId=${customerId}`}
      >
        <Box
          background={"#EDF2F7"}
          height={"40px"}
          width={"48px"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius={"12px"}
          style={{ cursor: "pointer" }}
        >
          <Image src={Edit} alt="" />
        </Box>
      </Link>
    </Flex>
  );
};

const ProfileWithClientName = () => {
  return (
    <Flex gap={"15px"} alignItems={"center"}>
      <Box
        background={"#EDF2F7"}
        height={"32px"}
        width={"32px"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius={"50%"}
        style={{ cursor: "pointer" }}
      >
        {/* <Image src={Eye} alt="" /> */}
      </Box>

      <Box>
        <Text>Tarun Kumar</Text>
      </Box>
    </Flex>
  );
};

const ClientRoleTable = ({
  clientsOfCustomer,
  customerProfileImgUrls,
  customerId,
}) => {
  const Header = [
    {
      displayText: "Client Name",
      keyText: "clientName",
      hasSort: false,
    },
    // {
    //   displayText: "Buisness Type",
    //   keyText: "buisnessType",
    //   hasSort: true,
    // },
    {
      displayText: "Registered Address",
      keyText: "address",
      hasSort: false,
    },
    {
      displayText: "Email",
      keyText: "email",
      hasSort: false,
    },
    {
      displayText: "Created Date",
      keyText: "createdDate",
      hasSort: true,
    },
    {
      displayText: "Actions",
      keyText: "actions",
    },
  ];

  const tableList = clientsOfCustomer?.map((client, index) => {
    return {
      clientName: (
        <AvatarWithName
          avatarUrl={
            customerProfileImgUrls ? customerProfileImgUrls[index]?.url : ""
          }
          name={capitalizeStringUpdated(client.clientName)}
        />
      ),
      // buisnessType: "Expert Traders",
      address: <Address clientId={client.clientId} customerId={customerId} />,
      email:
        <Email clientId={client.clientId} customerId={customerId} /> ||
        "example@example.com",
      createdDate: extractDayMonthYearFromTimestamp(client.creationDt),
      actions: <Icons clientId={client.clientId} customerId={customerId} />,
    };
  });
  return (
    <Box background={"white"} borderRadius={"12px"}>
      <Table
        overflow="auto"
        overflowY="auto"
        // tableContainerHeight={{ base: "441px", sm: "378px" }}
        headerList={Header}
        dataList={tableList}
        stickyColumn={["Actions"]}
      />
    </Box>
  );
};

export default ClientRoleTable;
