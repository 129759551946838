import { Select } from "@agnext/reactlib";
import _ from "lodash";

const elementOptions = [
  { label: "Radio", value: "radio" },
  { label: "Checkbox", value: "checkbox" },
  { label: "Checkbox With Search", value: "checkboxSearch" },
  { label: "Text Input", value: "textInput" },
  { label: "Number Input", value: "numberInput" },
  { label: "Dropdown", value: "dropdown" },
  { label: "Dropdown With Search", value: "dropdownWithSearch" },
  // { label: "Switch", value: "switch" },// Pointing to component which is a single checkbox with default lable switch
  { label: "Textarea", value: "textarea" },
  { label: "Datepicker", value: "datepicker" },
  // { label: "Rangepicker", value: "rangepicker" }, No element mapped
  // { label: "DateTimePicker", value: "dateTimepicker" }, No element mapped
  // { label: "Link (anchor tag)", value: "link" }, Unable to add link add button not working
  // { label: "Container", value: "container" }, No element mapped
  // { label: "Tooltip", value: "tooltip" }, No element mapped
  // { label: "Button", value: "button" }, No element mapped
  // { label: "Editable", value: "editable" }, No element mapped
  // { label: "Single File UPload", value: "singleFileUpload" }, Mapped to "multiFileUpload" (Duplicate)
  { label: "Multi File Upload", value: "multiFileUpload" },
  { label: "Email", value: "email" },
  { label: "Mobile", value: "mobile" },
  { label: "File Upload", value: "fileUpload" },
];

const getElement = (value) => {
  let el = {};
  elementOptions.forEach((option) => {
    if (option.value === value) {
      el = { ...option };
    }
  });
  return el;
};

export default function SelectElements({ element, onElementChange }) {
  return (
    <Select
      value={element.value}
      options={_.orderBy(elementOptions, "value", "asc")}
      onChange={(e) => {
        onElementChange(getElement(e.target.value));
      }}
      placeholder="Select HTML element"
      focusBorderColor="login.500"
    />
  );
}
