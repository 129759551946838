import React from 'react';
import {ReactComponent as GaugeEncircle} from "../../../../../assets/svg/GaugeEncircle.svg"
import { Chart, ArcElement } from 'chart.js';
import 'chart.js/auto';
import { color } from '@chakra-ui/system';
Chart.register(ArcElement);

const smallStyle ={
  fontWeight:"400",
  fontSize:"14px",
  color:"#E2E8F0B2",
  marginLeft:"16px"

}
const NewGuageChart = ({ score, width, height }) => {
  const chartRef = React.useRef(null);
  React.useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [score, 100 - score],
          backgroundColor: ['#fff', 'rgba(255, 255, 255, 0.2)'],
          borderWidth: 0,
          borderRadius: {
            outerStart:[50,0],
            outerEnd:[0,50],
            innerStart: 50,
            innerEnd:50
          },
        }],
      },
      options: {
        cutout: '80%',
        rotation: -94.5,
        circumference: 189,
        startAngle: -90,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    });
  }, [score]);
  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        bottom: `30px`,
        position: 'relative',
      }}
    >
      {/* <div
        style={{
          position: 'absolute',
          top: '20px',
          left: '20px',
          color: '#CCCCCC',
          fontFamily: 'Arial, sans-serif',
          fontSize: '24px',
        }}
      >
        Completion Score
      </div> */}
      <canvas ref={chartRef} />
      <div
        style={{
          position: 'absolute',
          top: '61%',
          left: '51%',
          transform: 'translate(-50%, -50%)',
          color: 'white',
          fontFamily: 'Arial, sans-serif',
          fontSize: '28px',
          fontWeight: 'bold',
        }}
      > 
       <small style={smallStyle}>Score</small> <br />
        {score}%
      </div>
      
      <div
        style={{
          position: 'absolute',
          left: '6px',
          bottom: '25px',
          color: '#CCCCCC',
          fontFamily: 'Arial, sans-serif',
          fontSize: '12px',
        }}
      >
        0
      </div>
      <div
        style={{
          position: 'absolute',
          right: '-8px',
          bottom: '25px',
          color: '#CCCCCC',
          fontFamily: 'Arial, sans-serif',
          fontSize: '12px',
        }}
      >
        100%
      </div>
    </div>
  );
};
export default NewGuageChart;