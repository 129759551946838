import {
  Badge,
  Box,
  CircularProgress,
  Flex,
  Image,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { fetchSasurl } from "services/index";

const GHOST_IMAGE = "ghost-imagepng_perfeqt_cwmiwjlnt5tezvsv.png";

const DataComparision = ({
  getElements,
  sectionsForBlocks,
  data,
  diffElements,
  tabIndex,
  inspectionId,
}) => {
  const [blobNames, setBlobNames] = useState({});
  const [diffElementMap, setDiffElementMap] = useState([]);

  const fetchBlobName = async (fileName, image) => {
    try {
      const response = await fetchSasurl({ fileName });
      const sasURL = response && response.data.payload.url;
      setBlobNames((prevBlobNames) => ({
        ...prevBlobNames,
        [image]: sasURL,
      }));
    } catch (error) {
      console.error("Error fetching blob name:", error);
    }
  };

  useEffect(() => {
    if (data && data.length && sectionsForBlocks && sectionsForBlocks.length) {
      JSON.parse(JSON.stringify(sectionsForBlocks))
        .sort((a, b) => a.pageNumber - b.pageNumber)
        .forEach((section) => {
          const elements = getElements(section.id);

          if (elements && elements.length) {
            elements.forEach((element) => {
              const elementId = element.element.id;
              const elementData = data.find((obj) => obj[elementId]);
              let value = null;
              if (elementData) {
                if (elementId in elementData) {
                  value = elementData[elementId];
                }
              }
              if (
                elementData &&
                elementData[elementId] &&
                elementData[elementId].attachments &&
                (value.itemType === "multiFileUpload" ||
                  value.itemType === "singleFileUpload")
              ) {
                elementData[elementId].attachments.forEach((image) => {
                  fetchBlobName(image, image);
                });
              }
            });
          }
        });
    }
  }, [data, sectionsForBlocks]);

  useEffect(() => {
    if (diffElements?.length > 0) {
      const tempElementMap = diffElements.map((obj) => {
        let templateId = obj.diff[0].data.templateItemId;
        return { [templateId]: obj.diff };
      });
      setDiffElementMap(tempElementMap);
    }
  }, [diffElements?.length, tabIndex]);

  useEffect(() => {
    if (diffElements?.length > 0) {
      diffElements.forEach((diffElement) => {
        const localData = diffElement.diff[0].data;

        if (
          localData &&
          localData.itemInfo &&
          localData.itemInfo.hashArray?.length > 0
        ) {
          localData.itemInfo.hashArray.forEach((info) => {
            let imageName = info.name;
            imageName && fetchBlobName(imageName, imageName);
          });
        }

        const blockChainData = diffElement.diff[1].data;
        if (
          blockChainData &&
          blockChainData.itemInfo &&
          blockChainData.itemInfo.hashArray?.length > 0
        ) {
          blockChainData.itemInfo.hashArray.forEach((info) => {
            let imageName = info.name;
            imageName && fetchBlobName(imageName, imageName);
          });
        }
      });
    }
  }, [diffElements?.length]);

  let count = 0;
  let count2 = 0;

  const elementHeightsLeft = useRef({});
  const elementHeightsRight = useRef({});

  const formatDate = useCallback(
    (dateString) => {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return "N.A.";
      }
      const options = { day: "numeric", month: "short", year: "numeric" };
      return date.toLocaleDateString("en-GB", options);
    },
    [inspectionId]
  );

  useEffect(() => {
    // Update heights of left and right elements
    const updateElementHeights = () => {
      Object.keys(elementHeightsLeft.current).forEach((elementId) => {
        const leftHeight = elementHeightsLeft.current[elementId];
        const rightHeight = elementHeightsRight.current[elementId];
        const maxHeight = Math.max(leftHeight, rightHeight);

        if (leftHeight < maxHeight) {
          elementHeightsLeft.current[elementId] = maxHeight;
        }
        if (rightHeight < maxHeight) {
          elementHeightsRight.current[elementId] = maxHeight;
        }
      });
    };

    updateElementHeights();
  }, [elementHeightsLeft.current, elementHeightsRight.current]);

  console.log(sectionsForBlocks);

  return (
    <Box mt="30px" pt="0" h="auto">
      {sectionsForBlocks === null ||
      sectionsForBlocks === undefined ||
      sectionsForBlocks.length === 0 ? (
        <Flex justifyContent="center">
          <CircularProgress isIndeterminate color="teal.400" />
        </Flex>
      ) : (
        <Flex gap="64px">
          <Flex flex="1" direction="column">
            <Flex direction="column">
              <Text color="gray/500" fontWeight="600" fontSize="18px">
                Platform Data
              </Text>
              <Flex alignItems="center" gap="12px">
                <Box bg="#b98200" w="12px" h="12px" borderRadius="50%"></Box>
                <Text fontSize="14px" color="gray/500">
                  Discripencies
                </Text>
              </Flex>
            </Flex>

            {data &&
            data.length &&
            sectionsForBlocks &&
            sectionsForBlocks.length ? (
              JSON.parse(JSON.stringify(sectionsForBlocks))
                .sort((a, b) => a.pageNumber - b.pageNumber)
                .map((section) => {
                  let elements = getElements(section.id);

                  if (elements && elements.length) {
                    return elements.map((element) => {
                      const elementId = element.element.id;

                      const foundObject = diffElementMap.find((obj) =>
                        obj.hasOwnProperty(elementId)
                      );

                      let isDiff = false;
                      let localData = {};
                      if (foundObject) {
                        const diffArr = foundObject[elementId];
                        localData = diffArr[0].data;
                        isDiff = true;
                      }

                      if (tabIndex === 1 && isDiff === false) {
                        return <></>;
                      }
                      if (tabIndex === 2 && isDiff === true) {
                        return <></>;
                      }

                      const elementData =
                        data &&
                        data.length &&
                        data.find((obj) => obj[elementId]);
                      let value = null;
                      if (elementData) {
                        if (elementId in elementData) {
                          value = elementData[elementId];
                        }
                      }

                      if (elementData !== undefined && value) count += 1;
                      return elementData !== undefined && value ? (
                        <>
                          <Flex
                            ref={(el) =>
                              (elementHeightsLeft.current[elementId] =
                                el && el.clientHeight)
                            }
                            mt={
                              tabIndex === 1 && isDiff === true ? "8px" : "0px"
                            }
                            mb={
                              tabIndex === 1 && isDiff === true ? "8px" : "0px"
                            }
                            gap="10px"
                            w="100%"
                            p="16px"
                            id={elementId}
                            border={
                              isDiff
                                ? "1px solid var(--yellow-500, #D69E2E)"
                                : "none"
                            }
                            borderRadius={isDiff ? "8px" : "0px"}
                            background={
                              isDiff ? "var(--yellow-50, #FFFFF0)" : "none"
                            }
                            borderBottom={
                              !isDiff
                                ? "1px solid var(--Grey-Gray-200, #E2E8F0)"
                                : "1px solid var(--yellow-500, #D69E2E)"
                            }
                            pt="24px"
                            pb="24px"
                            height={
                              isDiff
                                ? elementHeightsRight.current[elementId] >
                                  elementHeightsLeft.current[elementId]
                                  ? elementHeightsRight.current[elementId] +
                                    "px"
                                  : "auto"
                                : "auto"
                            }
                          >
                            <Flex
                              border="1px solid #545454"
                              borderRadius="50%"
                              h="16px"
                              w="16px"
                              justifyContent="center"
                              alignItems="center"
                              padding="10px"
                            >
                              <Text fontSize="8px" color="#545454">
                                {count}
                              </Text>
                            </Flex>
                            <VStack gap="12px" alignItems="left" w="100%">
                              <Text
                                fontSize="16px"
                                fontWeight="500"
                                color="#2D3748"
                              >
                                {isDiff ? localData.title : value.title}
                              </Text>
                              {value.value && (
                                <Text
                                  fontSize="16px"
                                  fontWeight="400"
                                  color="#4A5568"
                                >
                                  {value.itemType === "datepicker"
                                    ? isDiff
                                      ? formatDate(localData.value)
                                      : formatDate(value.value)
                                    : isDiff
                                    ? localData.value
                                    : value.value}
                                </Text>
                              )}

                              {value.itemType === "multiFileUpload" ||
                              value.itemType === "singleFileUpload" ? (
                                isDiff ? (
                                  <Flex flexWrap="wrap" gap="10px" w="100%">
                                    {localData.itemInfo &&
                                      localData.itemInfo.hashArray &&
                                      localData.itemInfo.hashArray.map(
                                        (info) => {
                                          let imageName = info.name;
                                          let modified = info.modified;

                                          return (
                                            <Tooltip
                                              hasArrow
                                              isDisabled={modified === false}
                                              label={
                                                modified !== false
                                                  ? `Image is ${modified?.toLowerCase()} from the platform`
                                                  : ""
                                              }
                                            >
                                              <VStack w="30%">
                                                <Image
                                                  key={imageName}
                                                  h="94px"
                                                  w="100%"
                                                  src={blobNames[imageName]}
                                                  borderRadius="8px"
                                                  border="1px solid #cbd5e0"
                                                />
                                                {modified !== false && (
                                                  <Badge
                                                    colorScheme={
                                                      modified === "Deleted"
                                                        ? "red"
                                                        : "blue"
                                                    }
                                                  >
                                                    {modified?.toUpperCase()}
                                                  </Badge>
                                                )}
                                              </VStack>
                                            </Tooltip>
                                          );
                                        }
                                      )}
                                  </Flex>
                                ) : (
                                  <Flex flexWrap="wrap" gap="10px" w="100%">
                                    {value.attachments &&
                                    value.attachments.length ? (
                                      value.attachments.map((imageName) => (
                                        <Box w="30%">
                                          <Image
                                            key={imageName}
                                            w="100%"
                                            h="94px"
                                            src={blobNames[imageName]}
                                            borderRadius="8px"
                                            border="1px solid #cbd5e0"
                                          />
                                        </Box>
                                      ))
                                    ) : (
                                      <></>
                                    )}
                                  </Flex>
                                )
                              ) : (
                                <Text
                                  fontSize="16px"
                                  fontWeight="400"
                                  color="#4A5568"
                                >
                                  {isDiff
                                    ? localData.attachments.join(", ")
                                    : value.attachments.join(", ")}
                                </Text>
                              )}
                            </VStack>
                          </Flex>
                        </>
                      ) : (
                        <></>
                      );
                    });
                  }
                })
            ) : (
              <></>
            )}
          </Flex>

          <Flex flex="1" direction="column">
            <Flex direction="column">
              <Text color="gray/500" fontWeight="600" fontSize="18px">
                Hedera Verified Data
              </Text>
              <Flex alignItems="center" gap="12px">
                <Box bg="#38A169" w="12px" h="12px" borderRadius="50%"></Box>
                <Text fontSize="14px" color="gray/500">
                  Verified
                </Text>
              </Flex>
            </Flex>

            {data &&
            data.length &&
            sectionsForBlocks &&
            sectionsForBlocks.length ? (
              JSON.parse(JSON.stringify(sectionsForBlocks))
                .sort((a, b) => a.pageNumber - b.pageNumber)
                .map((section) => {
                  let elements = getElements(section.id);

                  if (elements && elements.length) {
                    return elements.map((element) => {
                      const elementId = element.element.id;

                      const foundObject = diffElementMap.find((obj) =>
                        obj.hasOwnProperty(elementId)
                      );

                      let isDiff = false;
                      let blockchainData = {};
                      if (foundObject) {
                        const diffArr = foundObject[elementId];
                        blockchainData = diffArr[1].data;
                        isDiff = true;
                      }

                      if (tabIndex === 1 && isDiff === false) {
                        return <></>;
                      }
                      if (tabIndex === 2 && isDiff === true) {
                        return <></>;
                      }

                      const elementData =
                        data &&
                        data.length &&
                        data.find((obj) => obj[elementId]);
                      let value = null;
                      if (elementData) {
                        if (elementId in elementData) {
                          value = elementData[elementId];
                        }
                      }

                      if (elementData !== undefined && value) count2++;
                      return elementData !== undefined && value ? (
                        <>
                          <Flex
                            ref={(el) =>
                              (elementHeightsRight.current[elementId] =
                                el && el.clientHeight)
                            }
                            mt={
                              tabIndex === 1 && isDiff === true ? "8px" : "0px"
                            }
                            mb={
                              tabIndex === 1 && isDiff === true ? "8px" : "0px"
                            }
                            p="16px"
                            gap="10px"
                            w="100%"
                            id={elementId}
                            border={
                              isDiff
                                ? "1px solid var(--Green-Green-500, #38A169)"
                                : "none"
                            }
                            borderRadius={isDiff ? "8px" : "0px"}
                            background={
                              isDiff ? "var(--green-50, #F0FFF4)" : "none"
                            }
                            borderBottom={
                              !isDiff
                                ? "1px solid var(--Grey-Gray-200, #E2E8F0)"
                                : "1px solid var(--Green-Green-500, #38A169)"
                            }
                            pt="24px"
                            pb="24px"
                            height={
                              isDiff
                                ? elementHeightsLeft.current[elementId] >
                                  elementHeightsRight.current[elementId]
                                  ? elementHeightsLeft.current[elementId] + "px"
                                  : "auto"
                                : "auto"
                            }
                          >
                            <Flex
                              border="1px solid #545454"
                              borderRadius="50%"
                              h="16px"
                              w="16px"
                              justifyContent="center"
                              alignItems="center"
                              padding="10px"
                            >
                              <Text fontSize="8px" color="#545454">
                                {count2}
                              </Text>
                            </Flex>
                            <VStack gap="12px" alignItems="left" w="100%">
                              <Text
                                fontSize="16px"
                                fontWeight="500"
                                color="#2D3748"
                              >
                                {isDiff ? blockchainData.title : value.title}
                              </Text>
                              {value.value && (
                                <Text
                                  fontSize="16px"
                                  fontWeight="400"
                                  color="#4A5568"
                                >
                                  {value.itemType === "datepicker"
                                    ? isDiff
                                      ? formatDate(blockchainData.value)
                                      : formatDate(value.value)
                                    : isDiff
                                    ? blockchainData.value
                                    : value.value}
                                </Text>
                              )}

                              {value.itemType === "multiFileUpload" ||
                              value.itemType === "singleFileUpload" ? (
                                isDiff ? (
                                  <Flex flexWrap="wrap" gap="10px" w="100%">
                                    {blockchainData.itemInfo &&
                                      blockchainData.itemInfo.hashArray &&
                                      blockchainData.itemInfo.hashArray.map(
                                        (info) => {
                                          let imageName = info.name;
                                          let modified = info.modified;
                                          let hash = info.hash;

                                          return (
                                            <VStack w="30%">
                                              {modified !== false ? (
                                                <Tooltip
                                                  hasArrow
                                                  isDisabled={
                                                    modified === false
                                                  }
                                                  label={
                                                    modified !== false
                                                      ? hash || ""
                                                      : ""
                                                  }
                                                >
                                                  <Box
                                                    h="94px"
                                                    w="100%"
                                                    borderRadius="8px"
                                                    border="1px solid #cbd5e0"
                                                    p="10px"
                                                    background="var(--Grey-Gray-200, #E2E8F0)"
                                                  >
                                                    <Flex
                                                      justifyContent="center"
                                                      w="100%"
                                                      h="100%"
                                                      alignItems="center"
                                                    >
                                                      <Text
                                                        color="var(--gray-600, #4A5568)"
                                                        fontSize="12px"
                                                        overflow="hidden"
                                                        textOverflow="ellipsis"
                                                        whiteSpace="normal"
                                                      >
                                                        {hash || ""}
                                                      </Text>
                                                    </Flex>
                                                  </Box>
                                                </Tooltip>
                                              ) : (
                                                <Image
                                                  key={imageName}
                                                  h="94px"
                                                  w="100%"
                                                  src={blobNames[imageName]}
                                                  borderRadius="8px"
                                                  border="1px solid #cbd5e0"
                                                />
                                              )}
                                            </VStack>
                                          );
                                        }
                                      )}
                                  </Flex>
                                ) : (
                                  <Flex flexWrap="wrap" gap="10px" w="100%">
                                    {value.attachments &&
                                    value.attachments.length ? (
                                      value.attachments.map((imageName) => (
                                        <Box w="30%">
                                          <Image
                                            key={imageName}
                                            w="100%"
                                            h="94px"
                                            src={blobNames[imageName]}
                                            borderRadius="8px"
                                            border="1px solid #cbd5e0"
                                          />
                                        </Box>
                                      ))
                                    ) : (
                                      <></>
                                    )}
                                  </Flex>
                                )
                              ) : (
                                <Text
                                  fontSize="16px"
                                  fontWeight="400"
                                  color="#4A5568"
                                >
                                  {isDiff
                                    ? blockchainData.attachments.join(", ")
                                    : value.attachments.join(", ")}
                                </Text>
                              )}
                            </VStack>
                          </Flex>
                        </>
                      ) : (
                        <></>
                      );
                    });
                  }
                })
            ) : (
              <></>
            )}
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default DataComparision;
