import React, { useState, useEffect, useCallback} from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Container, Box, HStack, Flex, VStack, Tabs, TabList, TabPanels, Tab, Text, TabPanel } from "@chakra-ui/react";
import { Loader } from "../../../../components/Loader/Loader";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { useDispatch, useSelector } from "react-redux";
import { actions, sliceKey, reducer } from "./slice";
import { taskDataSaga } from "./saga";
import * as selectors from "./selectors";
import Card from "./elements/Card";

function InspectionDetail() {
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: taskDataSaga });

    const dispatch = useDispatch();

    const load = useSelector(selectors.loading);
    const taskData = useSelector(selectors.selectTaskData) || [];
    

    const scheduledTasks = taskData?.filter((task)=> task.status === 'todo')
    const inProgressTasks = taskData?.filter((task)=> task.status === 'inprogress')
    const completedTasks = taskData?.filter((task)=> task.status === 'done' || task.status === 'review-inprogress' || task.status === 'approved')

   

    const colors = ["#F2F3F6", "#F2F3F6", "#F2F3F6"];
    // const selectedStatus = { 0: ["todo"], 1: ["inprogress"], 2: ["done", "review-inprogress", "approved"] };
    const location = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);
    const initialTabIndex = parseInt(searchParams.get("tab")) || 0;



    const [tabIndex, setTabIndex] = useState(initialTabIndex);
    const bg = colors[tabIndex];


    useEffect(() => {
        dispatch(actions.fetchTaskData(null));
    }, [dispatch]);


    useEffect(() => {
        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.set("tab", tabIndex);
        history.replace({ search: newSearchParams.toString() });
    }, [tabIndex, location.search, history]);


    const handleTabChange =(index)=>{
        setTabIndex(index); 
}

  
    return (
        <>
            {" "}
            <Container mt="75px" minW={'70%'}>
                <Tabs
                index={tabIndex}
                    onChange={handleTabChange}
                    bg={bg}
                >
                    <TabList>
                        <HStack spacing="2rem" justify="space-around" w="100%">
                            <Tab
                                color="#414942"
                                fontWeight="500"
                                w="73px"
                                fontSize="14px"
                                borderBottom={tabIndex === 0 ? "3px solid green" : "none"}
                                _selected={{ borderBottom: "3 px solid green.500", color: "gray.600", fontWeight: "500" }}
                               onClick={()=>setTabIndex(0)}
                            >
                                Scheduled
                            </Tab>
                            <Tab color="#414942" fontWeight="500" minW={["110px", "150px"]} fontSize="14px" borderBottom={tabIndex === 1 ? "3px solid green" : "none"} _selected={{ borderBottom: "3 px solid green.500", color: "gray.600", fontWeight: "500" }}>
                                In Progress
                            </Tab>
                            <Tab color="#414942" fontWeight="500" w="73px" fontSize="14px" borderBottom={tabIndex === 2 ? "3px solid green" : "none"} _selected={{ borderBottom: "3 px solid green.500", color: "gray.600", fontWeight: "500" }} >
                                Completed
                            </Tab>
                        </HStack>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            {load == true ? (
                                <Loader position={"center"} margin="center" />
                            ) : (
                                <VStack spacing={4}>
                                    {scheduledTasks?.length == 0 ? (
                                        <Text fontSize={"20px"} mt={"30%"}>
                                            No Records Found...
                                        </Text>
                                    ) : (
                                        scheduledTasks.map((val) => {
                                            return <Card style={{ width: "100%" }} key={val.id} data={val}  />;
                                        })
                                    )}{" "}
                                </VStack>
                            )}
                        </TabPanel>
                        <TabPanel>
                            {load ? (
                                <Loader position={"center"} margin="center" />
                            ) : (
                                <VStack spacing={4}>
                                    {inProgressTasks.length == 0 ? (
                                        <Text fontSize={"20px"} mt={"30%"}>
                                            No Records Found...
                                        </Text>
                                    ) : (
                                        inProgressTasks.map((val) => {
                                            return <Card style={{ width: "100%" }} key={val.id} data={val}/>;
                                        })
                                    )}{" "}
                                </VStack>
                            )}
                        </TabPanel>
                        <TabPanel>
                            {load == true ? (
                                <Loader position={"center"} margin="center" />
                            ) : (
                                <VStack spacing={4}>
                                    {completedTasks.length == 0 ? (
                                        <Text fontSize={"20px"} mt={"30%"}>
                                            No Records Found...
                                        </Text>
                                    ) : (
                                        completedTasks.map((val) => {
                                            return <Card style={{ width: "100%" }} key={val.id} data={val}  />;
                                        })
                                    )}{" "}
                                </VStack>
                            )}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Container>
        </>
    );
}

export default InspectionDetail;
