import { createSlice } from "@reduxjs/toolkit";
import { generateUUId } from "utils/utils";

export const initialState = {
  loading: false,
  data: {},
};

const taskAnalyticsSlice = createSlice({
  name: "taskAnalytics",
  initialState,
  reducers: {
   fetchTaskAnalyticsData(state, action) {
    state.loading = true;
   },
   fetchTaskAnalyticsDataSuccess(state, action) {
    state.loading = false;
    state.data = action.payload

   },
   fetchTaskAnalyticsDataFailure(state, action) {
     state.loading = false
   }
  },
});

export const { actions, reducer, name: sliceKey } = taskAnalyticsSlice;
