import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "./slice";
import { formDataSaga } from "./saga";
import * as selectors from "./selectors";
import { useSelector, useDispatch } from "react-redux";
import HeaderGray from "../../components/headerGray";
import { HStack, VStack, Text, Box, Image, useMediaQuery, Stack, CircularProgress } from "@chakra-ui/react";
import { Button } from "@agnext/reactlib";
import Section from "./section";
import qualixLogo from "assets/qualix_logo.svg";
import { convertToCamelCase, generateUUId } from "utils/utils";
import { postData } from "services/index";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { userStorage } from "utils/helper";
import useNotificationToast from "utils/useToastNotification";
import inspeqtLogo from "../../../../assets/inspeqt-Logo-01.svg";

function hasPageNumber(arr, pageNumber) {
    let res = -1;
    arr.forEach((el, i) => {
        if (el.pageNumber === pageNumber) {
            res = i;
        }
    });
    return res;
}

export default function GenerateTemplate({ isFor }) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pwaInspectionId = queryParams.get("inspectionId");
    const pwaTaskId = queryParams.get("taskId");
    const pwaJobId = queryParams.get("jobId");
    const pwaAuthToken = JSON.parse(localStorage.getItem("inspectionDashboard"))?.token;
    const entityDetail = JSON.parse(localStorage.getItem("inspectionDashboard"))?.user;
    const entityId = entityDetail?.id;
    const entityRole = "auditor";

    const { id } = useParams();
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: formDataSaga });

    const dispatch = useDispatch();
    const history = useHistory();

    const [formData, setFormData] = useState({});

    const [invl, setInvl] = useState(null)

    const [formErrorData, setFormErrorData] = useState({});

    const [tempError, setTempError] = useState(false);

    const [page, setPage] = useState(0);

  const [pageStatus, setPageStatus] = useState({});

  const [filledPages, setFilledPages] = useState(0);

    const [skippedPages, setSkippedPages] = useState({ from: 0, to: 0 });

    const [isSubmit, setIsSubmit] = useState(false);

    const [prevPages, setPrevPages] = useState([]);

  const nToast = useNotificationToast();

  const [isMobileScreen] = useMediaQuery("(max-width: 872px)");
  const [smallerScreen] = useMediaQuery("(max-width: 1300px)");

    const form = useSelector(selectors.selectForm);

    const [displayData, setDisplayData] = useState([]);

    const [navIndex, setNavIndex] = useState(null);

    const sectionsForBlocks = useSelector(selectors.selectSectionsForBlocks);

    const blocksForComposites = useSelector(selectors.selectBlocksForComposites);

    const compositesForComponents = useSelector(selectors.selectCompositesForComponents);

    const componentsForElements = useSelector(selectors.selectComponentsForElements);

    let pageInfoWeb = useSelector(selectors.selectLastUpdatedPages);

    const [pageInfoMobile, setPageInfoMobile] = useState({});

  const formLoading = useSelector(selectors.selectFormLoading);

  const selectFilesUploadLoader = useSelector(
    selectors.selectFilesUploadLoader
  ); //Used to disable next/submit button while upload process is in progress.

  const getElementsState = useCallback(
    (inspectionId) => {
      const elements = componentsForElements.reduce((acc, cv) => {
        const elementsCurr = cv.structure.elements.map((element) => element);
        elementsCurr.forEach((currEl) => {
          acc[currEl.id] = {
            title: currEl.label,
            name: convertToCamelCase(currEl.label),
            value: "",
            attachments: [],
            itemType: currEl.htmlType,
            mimeType: "NA",
            itemOrder: currEl.order,
            parentId: cv.id,
            parentType: "component",
            parentInfo: {
              id: cv.id,
              title: cv.title,
            },
            id: generateUUId(),
            formId: form[0].id,
            templateItemId: currEl.id,
            itemInfo: currEl.properties
              ? { properties: currEl.properties }
              : {},
            inspectionId: inspectionId,
            mandatory: currEl.mandatory,
            tableName: "inspectionItems",
          };
        });
        return acc;
      }, {});
      return elements;
    },
    [componentsForElements, form]
  );
  const getMandatoryStatusInElementState = useCallback(
    ({ newFormData, components }) => {
      const elements = components.reduce((acc, cv) => {
        const elementsCurr = cv.structure.elements.map((element) => element);
        elementsCurr.forEach((currEl) => {
          acc[currEl.id] = {
            ...newFormData[currEl.id],
            mandatory: currEl.mandatory,
          };
        });
        return acc;
      }, {});

        return elements;
    }, []);

    const getElements = useCallback(
      (blockId) => {
        let elementDisplayData = [];
        if (
          blocksForComposites &&
          compositesForComponents &&
          componentsForElements
        ) {
          blocksForComposites.forEach((blockForComposites) => {
            blockForComposites.structure.composites.forEach((composite) => {
              compositesForComponents.forEach((compositeForComponents) => {
                if (composite.id === compositeForComponents.id) {
                  compositeForComponents.structure.components.forEach(
                    (component) => {
                      componentsForElements.forEach((componentForElements) => {
                        if (componentForElements.id === component.id) {
                          componentForElements.structure.elements.forEach(
                            (element) => {
                              elementDisplayData.push({
                                blockId: blockForComposites.id,
                                element,
                              });
                            }
                          );
                        }
                      });
                    }
                  );
                }
              });
            });
          });
        }
        const elements = [];
        elementDisplayData.forEach((element) => {
          if (element.blockId === blockId) {
            elements.push(element);
          }
        });
        return elements;
      },
      [blocksForComposites, compositesForComponents, componentsForElements]
    );
  

    const getDisplayData = useCallback(
      (changePageStatus = false) => {
        let displayData = [];
        let newPageStatus = { ...pageStatus };
  
        if (sectionsForBlocks) {
          _.cloneDeep(sectionsForBlocks).sort((a,b)=>a.order - b.order).forEach((section, index) => {
            const pageNumber = section.order;
            displayData.push({
              pageNumber,
              sections: section?.structure?.blocks || [],
              pageId: section.id,
            });
            if (changePageStatus) {
              if (pageNumber === 1) {
                newPageStatus[pageNumber] = true;
              } else {
                newPageStatus[pageNumber] = false;
              }
            }
          });
        }
        return { displayData, newPageStatus };
      },
      [sectionsForBlocks]
    );

    useEffect(() => {
      if (pageInfoWeb && pageInfoWeb.page) {
        let newPage = pageInfoWeb.page;
        let newPrevPage = [...pageInfoWeb.prevPages];
        if (pageInfoWeb?.skippedPages) {
          if (pageInfoWeb?.skippedPages?.from === pageInfoWeb.page) {
            newPage = pageInfoWeb.skippedPages.to - 1;
            newPrevPage.push(pageInfoWeb.skippedPages.from);
          } else if (pageInfoWeb?.skippedPages?.to === pageInfoWeb.page) {
            newPrevPage.push(pageInfoWeb.skippedPages.to);
          } else {
            newPrevPage.push(newPage);
          }
        }
        setPage(newPage);
  
        setFilledPages(pageInfoWeb.page);
        if (pageInfoWeb.prevPages) {
          setPrevPages(newPrevPage);
        }
        if (pageInfoWeb.pageStatus) {
          setPageStatus(pageInfoWeb.pageStatus);
        }
      }
    }, [pageInfoWeb]);

    useEffect(() => {
      let newPageStatus = { ...pageStatus };
      newPageStatus[page + 1] = true;
      setPageStatus(newPageStatus);
    }, [page]);

  useEffect(() => {
    if (
      skippedPages.from &&
      skippedPages.to &&
      skippedPages.from < skippedPages.to - 1
    ) {
      let newPageStatus = { ...pageStatus };
      for (let i = skippedPages.from + 1; i < skippedPages.to; i++) {
        newPageStatus[i] = false;
      }
      setPageStatus(newPageStatus);
    }
  }, [skippedPages]);

  useEffect(() => {
    setDisplayData(getDisplayData().displayData);
    if (!pageInfoWeb && !pageInfoWeb?.page) {
      setPageStatus(getDisplayData(true).newPageStatus);
    }
  }, [getDisplayData]);

    useEffect(() => {
        if (isFor === "web") {
            setFormData(getElementsState());
        }
    }, [getElementsState]);

  const handleFormDataChange = (e, val, id, mimeType) => {
    setTempError(false);

        if (_.isArray(val)) {
            setFormData((prevState) => ({
                ...prevState,
                [id]: {
                    ...prevState[id],
                    attachments: [...val],
                    itemInfo: {
                        ...prevState[id]?.itemInfo,
                        hashArray: e,
                    },
                    mimeType,
                },
            }));
            return;
        }
        if (_.isArray(e)) {
            setFormData((prevState) => ({
                ...prevState,
                [id]: {
                    ...prevState[id],
                    attachments: e,
                    mimeType,
                },
            }));
            return;
        }
        if (_.isString(e) || _.isDate(e)) {
            setFormData((prevState) => ({
                ...prevState,
                [id]: {
                    ...prevState[id],
                    value: e,
                },
            }));
            return;
        }
        if (val && id) {
            setFormData((prevState) => ({
                ...prevState,
                [id]: {
                    ...prevState[id],
                    value: val,
                },
            }));
            return;
        }
        const { name, value, checked } = e.target;
        if (!val) {
            setFormData((prevState) => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    value: value || value === "" || value === 0 ? value : checked,
                },
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    value: val,
                },
            }));
        }
    };

    const getErrorState = useCallback(() => {
        let errorState = {};
        getDisplayData().displayData.forEach((elementData) => {
            const pageNumber = elementData.pageNumber;
            errorState[pageNumber] = {};
            const elements = [];
            elementData.sections.forEach((section) => {
                const newElements = getElements(section.id);
                elements.push(...newElements);
            });
            elements.forEach((element) => {
                errorState[pageNumber][element.element.id] = "";
            });
        });
        return errorState;
    }, [getDisplayData, getElements]);

    useEffect(() => {
        setFormErrorData(getErrorState());
    }, [getErrorState]);

  const handleValidate = (pageNumber) => {
    console.log(formErrorData)
    let isValid = true;
    Object.keys(formErrorData[pageNumber]).forEach((key) => {
      if (
        formData[key].mandatory &&
        !formData[key].value &&
        !formData[key]?.attachments?.length
      ) {
        isValid = false;
        let newFormErrorData = { ...formErrorData };
        newFormErrorData[pageNumber][key] = "This is a required field.";
        setFormErrorData(newFormErrorData);
        return isValid;
      }
    });
    return isValid;
  };

  const createPreview = async ({inspectionId, jobId, taskId}) => {

    dispatch(actions.createPreview({
      inspectionId,
        jobId,
        taskId,
        status: "preview",

    }))
  }
  const handleSubmit = async (status) => {
    // if (isFor == "web") {
    //   setIsSubmit(true);
    // } else {
    //   if (status === "submitted") {
    //     setIsSubmit(true);
    //   }
    // }
    if (!filledPages || filledPages <= page) {
      setFilledPages(page + 1);
    }

    let authToken = null,
        inspectionId,
        jobId,
        taskId;

        if (isFor !== "web") {
          if (pwaInspectionId) {
          
              const token = pwaAuthToken;
              const inspectionID = pwaInspectionId;
              const jobID = pwaJobId;
              const taskID = pwaTaskId;

              authToken = token;
              inspectionId = inspectionID;
              jobId = jobID;
              taskId = taskID;
          } else {
          
              const token = localStorage.getItem("token");
              const inspectionID = localStorage.getItem("inspectionId");
              const jobID = localStorage.getItem("jobId");
              const taskID = localStorage.getItem("taskId");

              authToken = JSON.parse(token);
              inspectionId = JSON.parse(inspectionID);
              jobId = JSON.parse(jobID);
              taskId = JSON.parse(taskID);
          }
      }

    //Below if(status ===  "done") sets attachment = [] & value = ""  for the skipped pages by checking the page status state.
    
    let newFormData = JSON.parse(JSON.stringify(formData))
    if(status ===  "done"){
      const { displayData } = getDisplayData();
          let i = 0;
          displayData.forEach((data) => {
            if (!pageStatus[data.pageNumber]) {
              data.sections.forEach((section) => {
                getElements(section.id).forEach((element) => {
                  if (i == 0) {
                    i++;
                    newFormData = {
                      ...formData,
                      [element.element.id]: {
                        ...formData[element.element.id],
                        value: "",
                        attachments: [],
                      },
                    };
                  } else {
                    newFormData = {
                      ...newFormData,
                      [element.element.id]: {
                        ...newFormData[element.element.id],
                        value: "",
                        attachments: [],
                      },
                    };
                  }
                });
              });
            }
          });
    }
    // setFormData(newFormData);
    
    const data = [];
    Object.keys(formData).forEach((el) => {
        data.push(formData[el]);
    });
    const commonData = {
      status,
      data,
      pageInfo:
        status === "inprogress" ? { page : page + 1 , pageStatus, prevPages,skippedPages } : null,
      inspectionId,
      jobId,
      taskId,
    };

    const postRes =
        isFor === "web"
            ? await postData(commonData)
            : await postData({
                  ...commonData,
                  token: authToken,
              });

    if (postRes?.data?.success === true && status === "done") {
        //Do not remove this console log as it is being used as a flag for android app.
        isFor === "web" ? history.push("/admin/form-submitted") : console.log("Form Submitted Successfully");
        
        if(invl != null){
          clearInterval(invl)
        }

        createPreview({inspectionId, jobId, taskId});
        
    }

    if (postRes?.data?.success === false && status === "done") {
        //Do not remove this console log as it is being used as a flag for android app.
        console.log("Form Submission Failed");

        // setIsSubmit(false);
    }
};

const getCoordinates = () => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                resolve({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            },
            (error) => {
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        // Handle the case where the user denies permission
                        reject("Permission denied. Please enable location services.");
                        break;
                    case error.POSITION_UNAVAILABLE:
                        // Handle the case where the position is unavailable
                        reject("Location information is unavailable.");
                        break;
                    case error.TIMEOUT:
                        // Handle the case where the request timed out
                        reject("The request to get user location timed out.");
                        break;
                    case error.UNKNOWN_ERROR:
                        // Handle any other unknown errors
                        reject("An unknown error occurred while getting the location.");
                        break;
                }
            }
        );
    });
};

useEffect(() => {
  if(!pwaInspectionId) return

    const fetchCoordinates = async () => {
        try {
            const coordinates = await getCoordinates();
            dispatch(actions.fetchLocation({ coordinates: { latitude: coordinates.latitude, longitude: coordinates.longitude }, entityId, entityRole, pwaInspectionId, pwaJobId, pwaTaskId }));
        } catch (err) {
            if (err === "Permission denied. Please enable location services.") {
                alert(err);
                clearInterval(intervalId);
                history.goBack();
            } else {
                history.goBack();
            }
        }
    };
    fetchCoordinates();
    const intervalId = setInterval(() => {
        fetchCoordinates();
    }, 300000);

    setInvl(intervalId);

    return () => {
        clearInterval(intervalId);
    };
}, []);

  useEffect(() => {
    let inspectionId;
    if (pwaInspectionId){
      const id = pwaInspectionId;
      inspectionId = id;
    }
    else {
      const id = localStorage.getItem("inspectionId");
      inspectionId = JSON.parse(id);
    }
   
    setFormData(getElementsState(inspectionId));
  }, [getElementsState]);

    useEffect(() => {
        let authToken = null,
            inspectionId,
            jobId,
            taskId;

        if (isFor !== "web") {
            if (pwaInspectionId) {
           
                const token = pwaAuthToken;
                const inspectionID = pwaInspectionId;
                const jobID = pwaJobId;
                const taskID = pwaTaskId;

                authToken = token;
                inspectionId = inspectionID;
                jobId = jobID;
                taskId = taskID;
            } else {
              const token = localStorage.getItem("token");
              const inspectionID = localStorage.getItem("inspectionId");
                const jobID = localStorage.getItem("jobId");
                const taskID = localStorage.getItem("taskId");

                authToken = JSON.parse(token);
                inspectionId = JSON.parse(inspectionID);
                jobId = JSON.parse(jobID);
                taskId = JSON.parse(taskID);
            }
        }

    const setFormDataOnInitialLoad = ({ data, components }) => {
      let newFormData = getElementsState(inspectionId);
      data.forEach((element) => {
        const templateItemId = element.templateItemId;
        newFormData = {
          ...newFormData,
          [templateItemId]: {
            ...element,
          },
        };
      });
      const newFormDataWithMandatoryStatus = getMandatoryStatusInElementState({
        newFormData,
        components,
      });
      setFormData(newFormDataWithMandatoryStatus);
    };

    // const inspectionId = localStorage.getItem("inspectionId");
    // const inspectionId = JSON.parse(inspectionId);
    // const pageInfoJSON = localStorage.getItem("pageInfo");

    // const token = localStorage.getItem("token");
    // const authToken = token && JSON.parse(token);

    // if (pageInfo && pageInfo.page) {
    //   setPageInfoMobile(pageInfo);
    // }

        const formId = isFor == "web" ? (userStorage?.get()?.id === "02c34174-d003-4f9b-a84b-043d24914e2f" ? "u5k54m6cgiyqiwwi" : "h0xpf4h5l2s5xrv4") : id;

        const cleanupFormState = () => {
            setFormData({});
            setFormErrorData({});
            setTempError(false);
            setPage(0);
            setPageStatus({});
            setSkippedPages({ from: 0, to: 0 });
            setIsSubmit(false);
            setPrevPages([]);
        };

    dispatch(
      actions.fetchFormData({
        id: formId,
        ...(isFor !== "web" && { token: authToken }),
        onSuccess: (components) => {
          cleanupFormState();
          dispatch(
            actions.fetchSingleTask({
              inspectionId,
              jobId,
              taskId,
              ...(isFor !== "web" && { token: authToken }),
              onSuccess: () => {
                dispatch(
                  actions.fetchReviewData({
                    onSuccess: setFormDataOnInitialLoad,
                    components,
                    inspectionId,
                    jobId,
                    taskId,
                    ...(isFor !== "web" && { token: authToken }),
                  })
                );
              },
            })
          );
        },
      })
    );
  }, [dispatch]);

  if (formLoading) {
    return (
      <HStack>
        <CircularProgress
          isIndeterminate
          color="#4FD1C5"
          size="26px"
          alignSelf={"center"}
        />
        <Text>Loading...</Text>
      </HStack>
    );
  }
  return (
    <Box mt={isFor === "web" ? "75px" : "0px"} w="100%">
      <HeaderGray
        form={form && form[0]}
        pageNumber={page}
        numPages={getDisplayData().displayData.length}
        filledPages={filledPages ? filledPages : page}
      />
      <Box w="100%" style={{ background: "#F9F9FB", position: "relative" }}>
        {smallerScreen ? (
          <></>
        ) : isFor === "web" ? (
          <VStack
            justify="flex-end"
            align="flex-start"
            gap="2px"
            style={{ position: "absolute", left: "72px", bottom: "64px" }}
          >
            <Text
              color="#4A5568"
              fontSize="14px"
              fontWeight="400"
              lineHeight="1.4"
            >
              Powered by
            </Text>
            <Image src={inspeqtLogo} alt="perfeqt logo" />
          </VStack>
        ) : (
          <></>
        )}
        <VStack
          w={isMobileScreen ? "100%" : "740px"}
          style={{
            margin: "0 auto",
            padding: "64px 24px",
          }}
          gap="64px"
          area-label="sections"
          justify="flex-start"
          align="flex-start"
        >
          {displayData &&
            displayData[page] &&
            displayData[page].sections.map((section) => {
              return (
                <Section
                  key={section.id}
                  section={section}
                  data={getElements(section.id)}
                  formData={formData}
                  formErrorData={formErrorData[page + 1]}
                  handleFormDataChange={handleFormDataChange}
                  displayData={displayData}
                  setPage={setPage}
                  navIndex={navIndex}
                  setNavIndex={setNavIndex}
                  tempError={tempError}
                  setSkippedPages={setSkippedPages}
                  prevPages={prevPages}
                  page={page}
                />
              );
            })}
          {tempError ? (
            <HStack w="100%" justify="center" align="center">
              <Text
                aria-label="element label"
                color="#D93025"
                fontSize="14px"
                fontWeight="400"
                lineHeight="1.4"
                textAlign="center"
              >
                Please, Fill in all the mandatory fields.
              </Text>
            </HStack>
          ) : (
            <></>
          )}
          {getDisplayData().displayData.length === 1 ? (
            <Button
              isDisabled={
                Object.values(formData).some((element) => {
                  return element?.parentInfo?.title === "Disclaimer" &&
                    element.value === "No, I would like to revisit my response"
                    ? true
                    : false;
                }) ||
                (selectFilesUploadLoader &&
                  Object.values(selectFilesUploadLoader).includes(true))
              }
              label={"Submit"}
              colorScheme="login"
              size="sm"
              sx={{ width: "100%" }}
              onClick={() => {
                console.log("Pagevalue:",page)
                if (handleValidate(page + 1)) {
                  handleSubmit("done");
                } else {
                  setTempError(true);
                }
              }}
            />
          ) : page === 0 ? (
            <Button
              isDisabled={
                selectFilesUploadLoader &&
                Object.values(selectFilesUploadLoader).includes(true)
              }
              label={"Next"}
              colorScheme="login"
              size="sm"
              sx={{ width: "100%" }}
              onClick={() => {
                if (handleValidate(page + 1)) {
                  setPage(navIndex ? navIndex : page + 1);
                  // Ensure newPrevPages is always an array
                  let newPrevPages = Array.isArray(prevPages)
                    ? [...prevPages]
                    : [];
                  handleSubmit("inprogress");
                  newPrevPages.push(page + 1);
                  setPrevPages(newPrevPages);
                  setNavIndex(null);
                } else {
                  setTempError(true);
                }
              }}
            />
          ) : page === getDisplayData().displayData.length - 1 ? (
            <HStack gap="16px" w="100%">
              <Button
                isDisabled={
                  selectFilesUploadLoader &&
                  Object.values(selectFilesUploadLoader).includes(true)
                }
                label={"Back"}
                colorScheme="login"
                variant="outline"
                size="sm"
                sx={{ width: "100%" }}
                onClick={() => {
                  setPage(
                    prevPages.length
                      ? prevPages[prevPages.length - 1] - 1
                      : page - 1
                  );
                  if (prevPages.length) {
                    const newPrevPages = [...prevPages];
                    newPrevPages.pop();
                    setPrevPages(newPrevPages);
                  }
                }}
              />
              <Button
                isDisabled={
                  Object.values(formData).some((element) => {
                    return element?.parentInfo?.title === "Disclaimer" &&
                      element.value ===
                        "No, I would like to revisit my response"
                      ? true
                      : false;
                  }) ||
                  (selectFilesUploadLoader &&
                    Object.values(selectFilesUploadLoader).includes(true))
                }
                label={"Submit"}
                colorScheme="login"
                size="sm"
                sx={{ width: "100%" }}
                onClick={() => {
                  if (handleValidate(page + 1)) {
                    handleSubmit("done");
                  } else {
                    setTempError(true);
                  }
                }}
              />
            </HStack>
          ) : (
            <HStack gap="16px" w="100%">
              <Button
                isDisabled={
                  selectFilesUploadLoader &&
                  Object.values(selectFilesUploadLoader).includes(true)
                }
                label={"Back"}
                colorScheme="login"
                variant="outline"
                size="sm"
                sx={{ width: "100%" }}
                onClick={() => {
                  setPage(
                    prevPages.length
                      ? prevPages[prevPages.length - 1] - 1
                      : page - 1
                  );
                  if (prevPages.length) {
                    const newPrevPages = [...prevPages];
                    newPrevPages.pop();
                    setPrevPages(newPrevPages);
                    setNavIndex(null);
                  }
                }}
              />
              <Button
                isDisabled={
                  selectFilesUploadLoader &&
                  Object.values(selectFilesUploadLoader).includes(true)
                }
                label={"Next"}
                colorScheme="login"
                size="sm"
                sx={{ width: "100%" }}
                onClick={() => {
                  // ////console.log({ validation: handleValidate(page + 1) });
                  if (handleValidate(page + 1)) {
                    setPage(navIndex ? navIndex : page + 1);

                    // Ensure newPrevPages is always an array
                    let newPrevPages = Array.isArray(prevPages)
                      ? [...prevPages]
                      : [];
                    handleSubmit("inprogress");
                    newPrevPages.push(page + 1);
                    setPrevPages(newPrevPages);
                    setNavIndex(null);
                  } else {
                    setTempError(true);
                  }
                }}
              />
            </HStack>
          )}
        </VStack>
      </Box>
    </Box>
  );
}

/*
Code below is for future reference in case some issue is found. It can be removed after canary testing
{page === getDisplayData().displayData.length - 1 && page !== 0 ? (
            <HStack gap="16px" w="100%">
              <Button
                isDisabled={
                  selectFilesUploadLoader &&
                  Object.values(selectFilesUploadLoader).includes(true)
                }
                label={"Back"}
                colorScheme="login"
                variant="outline"
                size="sm"
                sx={{ width: "100%" }}
                onClick={() => {
                  setPage(
                    prevPages.length
                      ? prevPages[prevPages.length - 1] - 1
                      : page - 1
                  );
                  if (prevPages.length) {
                    const newPrevPages = [...prevPages];
                    newPrevPages.pop();
                    setPrevPages(newPrevPages);
                  }
                }}
              />
              <Button
                isDisabled={
                  Object.values(formData).some((element) => {
                    return element?.parentInfo?.title === "Disclaimer" &&
                      element.value ===
                        "No, I would like to revisit my response"
                      ? true
                      : false;
                  }) ||
                  (selectFilesUploadLoader &&
                    Object.values(selectFilesUploadLoader).includes(true))
                }
                label={"Submit"}
                colorScheme="login"
                size="sm"
                sx={{ width: "100%" }}
                onClick={() => {
                  if (handleValidate(page + 1)) {
                    handleSubmit("done");
                  } else {
                    setTempError(true);
                  }
                }}
              />
            </HStack>
          ) : page === 0 ? (
            <Button
              isDisabled={
                selectFilesUploadLoader &&
                Object.values(selectFilesUploadLoader).includes(true)
              }
              label={"Next"}
              colorScheme="login"
              size="sm"
              sx={{ width: "100%" }}
              onClick={() => {
                if (handleValidate(page + 1)) {
                  setPage(navIndex ? navIndex : page + 1);
                  // Ensure newPrevPages is always an array
                  let newPrevPages = Array.isArray(prevPages)
                    ? [...prevPages]
                    : [];
                  handleSubmit("inprogress");
                  newPrevPages.push(page + 1);
                  setPrevPages(newPrevPages);
                  setNavIndex(null);
                } else {
                  setTempError(true);
                }
              }}
            />
          ) : (
            <HStack gap="16px" w="100%">
              <Button
                isDisabled={
                  selectFilesUploadLoader &&
                  Object.values(selectFilesUploadLoader).includes(true)
                }
                label={"Back"}
                colorScheme="login"
                variant="outline"
                size="sm"
                sx={{ width: "100%" }}
                onClick={() => {
                  setPage(
                    prevPages.length
                      ? prevPages[prevPages.length - 1] - 1
                      : page - 1
                  );
                  if (prevPages.length) {
                    const newPrevPages = [...prevPages];
                    newPrevPages.pop();
                    setPrevPages(newPrevPages);
                    setNavIndex(null);
                  }
                }}
              />
              <Button
                isDisabled={
                  selectFilesUploadLoader &&
                  Object.values(selectFilesUploadLoader).includes(true)
                }
                label={"Next"}
                colorScheme="login"
                size="sm"
                sx={{ width: "100%" }}
                onClick={() => {
                  // ////console.log({ validation: handleValidate(page + 1) });
                  if (handleValidate(page + 1)) {
                    setPage(navIndex ? navIndex : page + 1);

                    // Ensure newPrevPages is always an array
                    let newPrevPages = Array.isArray(prevPages)
                      ? [...prevPages]
                      : [];
                    handleSubmit("inprogress");
                    newPrevPages.push(page + 1);
                    setPrevPages(newPrevPages);
                    setNavIndex(null);
                  } else {
                    setTempError(true);
                  }
                }}
              />
            </HStack>
          )}

*/
